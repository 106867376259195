var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import cn from "classnames";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { fonts } from "@basics/fonts";
import { Col, Container, MARGIN_OF_CONTAINER, onBefore, onBreakpoint, rem, Row, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { AdaptiveImageComponent } from "@components/AdaptiveImageComponent/AdaptiveImageComponent";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { Button, ButtonWrapper, isExternal, isFile, } from "@components/Button/Button";
import { CloudinaryVideoPlayer } from "@components/CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import { Event, getNewsWallData, } from "@components/Page/NewsWallPage";
import { Spinner } from "@components/Spinner/Spinner";
import { namePropertyValue } from "@models/utils";
import { RichTextWithTypographyRenderer } from "./RichTextBlock/RichTextBlock";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    element: __assign(__assign(__assign({ marginBottom: rem(spacings.m), paddingTop: function (isThemeHomepage) {
            return isThemeHomepage ? 0 : rem(spacings.m);
        }, paddingBottom: function (isThemeHomepage) {
            return isThemeHomepage ? 0 : rem(spacings.l);
        } }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
        paddingTop: function (isThemeHomepage) {
            return isThemeHomepage ? 0 : rem(spacings.l);
        },
        paddingBottom: function (isThemeHomepage) {
            return isThemeHomepage ? 0 : rem(spacings.xl);
        },
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
        paddingTop: function (isThemeHomepage) {
            return isThemeHomepage ? 0 : rem(spacings.xl.times(2));
        },
        paddingBottom: function (isThemeHomepage) {
            return isThemeHomepage ? 0 : rem(spacings.xxl);
        },
    })), { position: "relative" }),
    desktopOnly: __assign({ display: "none" }, onBreakpoint("md", {
        display: "block",
    })),
    tabletOnly: __assign(__assign({ display: "none" }, onBreakpoint("sm", {
        display: "block",
    })), onBreakpoint("md", {
        display: "none",
    })),
    mobileEvents: __assign({ display: "block", backgroundColor: function (isThemeHomepage) {
            return isThemeHomepage ? brandColors.ultraLightGrey : brandColors.white;
        }, marginTop: rem(spacings.s), paddingTop: rem(spacings.sam), paddingLeft: rem(spacings.s), paddingRight: rem(spacings.s) }, onBreakpoint("sm", {
        display: "none",
    })),
    hideOnMobile: __assign({ display: "none" }, onBreakpoint("sm", {
        display: "block",
    })),
    mainHeadline: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    blockOnDesktop: __assign({}, onBreakpoint("md", {
        display: "none",
    })),
    customEventBorder: __assign({ borderBottom: "2px solid ".concat(brandColors.lightGrey) }, onBreakpoint("md", {
        borderBottom: "none",
    })),
    headline: __assign({}, customTypography(__assign(__assign({}, typography.h2), { marginBottom: rem(spacings.xl) }), {}, {
        marginBottom: rem(spacings.xl),
    })),
    eventsHeader: typography.textLarge,
    background: {
        position: "absolute",
        backgroundColor: function (isThemeHomepage) {
            return isThemeHomepage ? brandColors.white : brandColors.ultraLightGrey;
        },
        height: "100%",
        top: 0,
        width: "calc(2 * ".concat(MARGIN_OF_CONTAINER, " + 100%)"),
        left: "calc(-1 * ".concat(MARGIN_OF_CONTAINER, ")"),
    },
    secondRow: __assign(__assign(__assign({ marginTop: rem(spacings.s) }, onBreakpoint("sm", {
        marginTop: rem(spacings.sam),
    })), onBreakpoint("md", {
        marginTop: rem(spacings.m),
    })), { rowGap: rem(spacings.s) }),
    buttonRow: __assign({ marginTop: rem(spacings.m), display: "none" }, onBreakpoint("md", {
        display: "flex",
        justifyContent: "center",
    })),
    fullHeight: {
        height: "100%",
    },
});
export function NewsWallTeaserBlock(props) {
    var _a, _b, _c, _d;
    var styles = useStyles(props.theme === "homepage");
    var _e = useState(null), newsWallData = _e[0], setNewsWallData = _e[1];
    var _f = useState(false), isLoading = _f[0], setLoading = _f[1];
    var _g = useContext(GlobalContentStoreContext), getStartPageData = _g.getStartPageData, getImmediateStartPageData = _g.getImmediateStartPageData, getWebsiteData = _g.getWebsiteData;
    var websiteData = getWebsiteData();
    var _h = useState(getImmediateStartPageData()), startPageData = _h[0], setStartPageData = _h[1];
    var loadItems = useCallback(function (newsWallData) {
        var _a, _b, _c;
        var filters = ((_b = (_a = props.data.filters) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.map(function (filter) { return filter.id; })) || [];
        setLoading(true);
        return getNewsWallData(1, (newsWallData === null || newsWallData === void 0 ? void 0 : newsWallData.value) || undefined, (_c = websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage.link) === null || _c === void 0 ? void 0 : _c.toLowerCase(), filters, [], true, true).then(function (response) {
            setNewsWallData(response);
            setLoading(false);
        });
    }, [(_a = props.data.filters) === null || _a === void 0 ? void 0 : _a.value, websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage.link]);
    var newsWallPage = props.data.useCustomNewsWallPage.value === "enabled"
        ? props.data.customNewsWallPage
        : startPageData === null || startPageData === void 0 ? void 0 : startPageData.newsWallPage;
    useEffect(function () {
        loadItems(newsWallPage);
    }, [loadItems, newsWallPage, startPageData]);
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    if (!startPageData)
        return null;
    if (isLoading)
        return React.createElement(Spinner, null);
    function convertToNewsWallApiHit(item) {
        var _a, _b, _c;
        function generateNewsWallTags(items) {
            return items.map(function (i) {
                return {
                    Name: namePropertyValue(i.name) || "",
                    ContentLink: String(i.contentLink.id),
                };
            });
        }
        var commonPart = {
            Id: item.contentLink.id,
            Title: item.title.value,
            Description: item.description.value,
            Url: item.url || "",
            Tags: generateNewsWallTags(item.visibleTags.expandedValue || []),
            Categories: generateNewsWallTags(item.categories.expandedValue || []),
        };
        var isArticle = function (i) {
            return i.contentType.join("/") === "Page/NewsArticlePage";
        };
        var isCrossReference = function (i) {
            return i.contentType.join("/") === "Page/CrossReferencePage";
        };
        var isEvent = function (i) {
            return i.contentType.join("/") === "Page/EventNewsPage";
        };
        var isVideo = function (i) {
            return i.contentType.join("/") === "Page/VideoNewsWallPage";
        };
        if (isArticle(item)) {
            var image = item.metaImageAdaptive.value;
            return __assign(__assign({}, commonPart), { Type: "Article", ImageUrlLarge: (image === null || image === void 0 ? void 0 : image.urlLarge) || null, ImageUrlMedium: (image === null || image === void 0 ? void 0 : image.urlMedium) || null, ImageUrlSmall: (image === null || image === void 0 ? void 0 : image.urlSmall) || null, ImageAlt: (image === null || image === void 0 ? void 0 : image.alternateText) || null, Date: item.date.value, Location: item.location.value, Author: item.author.value });
        }
        if (isCrossReference(item)) {
            var image = item.imageAdaptive.value;
            var link = (_a = item.link.value) === null || _a === void 0 ? void 0 : _a[0];
            return __assign(__assign({}, commonPart), { Type: "CrossReference", ImageUrlLarge: (image === null || image === void 0 ? void 0 : image.urlLarge) || null, ImageUrlMedium: (image === null || image === void 0 ? void 0 : image.urlMedium) || null, ImageUrlSmall: (image === null || image === void 0 ? void 0 : image.urlSmall) || null, ImageAlt: (image === null || image === void 0 ? void 0 : image.alternateText) || null, Link: (link === null || link === void 0 ? void 0 : link.href) || null, LinkText: (link === null || link === void 0 ? void 0 : link.text) || null });
        }
        if (isEvent(item)) {
            var image = item.headerImage.value;
            var link = (_b = item.link.value) === null || _b === void 0 ? void 0 : _b[0];
            return __assign(__assign({}, commonPart), { Type: "Event", ImageUrlLarge: (image === null || image === void 0 ? void 0 : image.urlLarge) || null, ImageUrlMedium: (image === null || image === void 0 ? void 0 : image.urlMedium) || null, ImageUrlSmall: (image === null || image === void 0 ? void 0 : image.urlSmall) || null, ImageAlt: (image === null || image === void 0 ? void 0 : image.alternateText) || null, DateFrom: item.date.value || "", DateTo: item.dateTo.value, Location: item.location.value || "", Link: (link === null || link === void 0 ? void 0 : link.href) || null, LinkText: (link === null || link === void 0 ? void 0 : link.text) || null });
        }
        if (isVideo(item)) {
            var videoBlock = (_c = item.videoBlockArea.expandedValue) === null || _c === void 0 ? void 0 : _c[0];
            var image = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.thumbnail.value;
            return __assign(__assign({}, commonPart), { Type: "Video", ImageUrlLarge: (image === null || image === void 0 ? void 0 : image.urlLarge) || null, ImageUrlMedium: (image === null || image === void 0 ? void 0 : image.urlMedium) || null, ImageUrlSmall: (image === null || image === void 0 ? void 0 : image.urlSmall) || null, ImageAlt: (image === null || image === void 0 ? void 0 : image.alternateText) || null, CloudinaryVideoPublicID: (videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.cloudinaryVideoPublicID.value) || null, StartAction: (videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.startAction.value) || null, EndAction: (videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.endAction.value) || null, ShowControls: (videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.showControls.value) || null });
        }
        return null;
    }
    var featured = (props.data.featuredArticles.expandedValue || [])
        .map(convertToNewsWallApiHit)
        .filter(function (x) { return Boolean(x); });
    var featuredIds = featured.map(function (f) { return f.Id; });
    var cards = __spreadArray(__spreadArray([], featured, true), ((newsWallData && newsWallData.Hits) || []).filter(function (h) { return !featuredIds.includes(h.Id); }), true);
    var showEvents = (((_b = newsWallData === null || newsWallData === void 0 ? void 0 : newsWallData.UpcomingEvents) === null || _b === void 0 ? void 0 : _b.length) || 0) !== 0 &&
        props.data.showEvents.value;
    var headerText = props.data.useCustomNewsWallPage.value === "enabled" ? (props.data.headline.value) : showEvents ? (React.createElement(LocalizedLabel, { section: "NewsWallTeaser", label: "InsightsEvents" })) : (React.createElement(LocalizedLabel, { section: "NewsWallTeaser", label: "RelatedInsights" }));
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { className: styles.element },
            React.createElement("div", { className: styles.background }),
            React.createElement(Col, { className: cn(styles.mainHeadline, showEvents && styles.blockOnDesktop) },
                React.createElement("h2", { className: styles.headline }, headerText)),
            React.createElement(Row, null,
                showEvents && (React.createElement(Col, { sm: 4 },
                    React.createElement("span", { className: styles.desktopOnly },
                        React.createElement("h2", { className: styles.headline }, headerText)),
                    React.createElement("span", { className: styles.tabletOnly },
                        React.createElement("span", { className: styles.eventsHeader },
                            React.createElement(LocalizedLabel, { section: "NewsWallPage", label: "UpcomingEvents" }))),
                    newsWallData && newsWallData.UpcomingEvents.length > 0 && (React.createElement("div", { className: styles.hideOnMobile }, newsWallData.UpcomingEvents.map(function (item, idx) { return (React.createElement(Event, __assign({}, item, { key: idx, className: styles.customEventBorder }))); }))))),
                React.createElement(Col, { sm: showEvents ? 8 : 12, smStart: showEvents ? 4 : undefined }, cards.slice(0, 1).map(function (card, cidx) { return (React.createElement(AnimatedRevealBlock, { key: cidx, className: styles.fullHeight },
                    React.createElement(FirstItemCard, __assign({}, card, { startPageData: startPageData, sideCard: !showEvents, theme: props.theme })))); }))),
            cards.length > 1 && (React.createElement(Row, { className: styles.secondRow }, cards.slice(1, 4).map(function (card, cidx) { return (React.createElement(Col, { key: cidx, sm: 4 },
                React.createElement(AnimatedRevealBlock, { revealDelay: cidx * 100 + 100, className: styles.fullHeight },
                    React.createElement(NewsWallTeaserCard, __assign({}, card, { startPageData: startPageData, theme: props.theme }))))); }))),
            React.createElement(Row, null,
                React.createElement(Col, { className: styles.buttonRow }, props.data.useCustomNewsWallPage.value === "enabled" ? (React.createElement(React.Fragment, null, (_c = props.data.customNewsWallPageButton.value) === null || _c === void 0 ? void 0 : _c.map(function (b, idx) { return (React.createElement(ButtonWrapper, __assign({}, b, { key: idx }))); }))) : (React.createElement(Button, { href: ((_d = startPageData.newsWallPage.value) === null || _d === void 0 ? void 0 : _d.url) || "", type: "secondary" },
                    React.createElement(LocalizedLabel, { section: "NewsWallTeaser", label: "NewsroomButton" }))))),
            showEvents && (React.createElement(Row, null,
                React.createElement(Col, { className: styles.mobileEvents },
                    React.createElement("span", { className: styles.eventsHeader },
                        React.createElement(LocalizedLabel, { section: "NewsWallPage", label: "UpcomingEvents" })),
                    newsWallData && newsWallData.UpcomingEvents.length > 0 && (React.createElement(React.Fragment, null, newsWallData.UpcomingEvents.map(function (item, idx) { return (React.createElement(Event, __assign({}, item, { key: idx, className: styles.customEventBorder }))); })))))))));
}
var useFirstChildCardStyles = createUseStyles({
    newsItem: {
        backgroundColor: function (isThemeHomepage) {
            return isThemeHomepage ? brandColors.ultraLightGrey : brandColors.white;
        },
        paddingBottom: rem(spacings.m),
    },
    sideCardNewsItem: {
        paddingBottom: 0,
    },
    newsItemSection: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: rem(spacings.m),
        paddingLeft: rem(spacings.m),
        paddingRight: rem(spacings.m),
    },
    sideCardNewsItemSection: __assign({}, onBreakpoint("sm", {
        gridRow: 1,
    })),
    h4: customTypography(typography.h4, {
        marginBottom: rem(spacings.s),
    }, {
        marginBottom: rem(spacings.sam),
    }, {
        marginBottom: rem(spacings.m),
    }),
    category: __assign(__assign({}, typography.caption), { display: "flex", flexDirection: "column", marginBottom: rem(spacings.xs) }),
    buttons: {
        marginTop: "auto",
    },
    sideCardButtons: {
        paddingBottom: rem(spacings.l),
    },
    nonBreakingSpan: {
        whiteSpace: "nowrap",
        //fix for Firefox
        "@supports (-moz-appearance:none)": {
            whiteSpace: "normal",
        },
        //fix for Safari
        "@media not all and (min-resolution:.001dpcm)": {
            whiteSpace: "normal",
        },
    },
    descriptionBox: __assign(__assign({ display: "none" }, onBreakpoint("md", {
        display: "block",
        position: "relative",
        flex: 1,
    })), { "&:after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: rem(spacings.m.times(2)),
            background: function (isThemeHomepage) {
                return isThemeHomepage
                    ? "linear-gradient(to bottom, ".concat(brandColors.ultraLightGrey, "00, ").concat(brandColors.ultraLightGrey, "FF 100%)")
                    : "linear-gradient(to bottom, ".concat(brandColors.white, "00, ").concat(brandColors.white, "FF 100%)");
            },
        } }),
    textDefault: __assign(__assign({ position: "absolute", height: "100%", overflow: "hidden" }, typography.textDefault), fonts.book),
    imageContainer: {
        overflow: "hidden",
    },
    image: {
        transform: "none",
        transition: "transform 0.3s ease",
        "&:hover": {
            transform: "scale(1.03)",
            transition: "transform 0.3s ease",
        },
    },
});
function FirstItemCard(props) {
    var _a, _b, _c;
    var styles = useFirstChildCardStyles(props.theme === "homepage");
    var _d = useState(false), forceVideoPlay = _d[0], setForceVideoPlay = _d[1];
    var isAutoplay = props.Type === "Video" && props.StartAction === "autoplay";
    var isLoop = props.Type === "Video" && props.EndAction === "loop_video";
    var resetOnEnd = props.Type === "Video" && props.EndAction === "reset_to_thumbnail";
    var defaultPosterUrl = props.Type === "Video" &&
        (props.ImageUrlLarge || props.ImageUrlMedium || props.ImageUrlSmall);
    return (React.createElement(Row, { className: cn(styles.newsItem, props.sideCard && styles.sideCardNewsItem), dataTestid: "NewsWallTeaserCard" },
        React.createElement(Col, { sm: props.sideCard ? 8 : 12, smStart: props.sideCard ? 4 : undefined, className: styles.imageContainer },
            (props.Type === "Article" || props.Type === "CrossReference") &&
                (props.ImageUrlLarge ||
                    props.ImageUrlMedium ||
                    props.ImageUrlSmall) && (React.createElement("a", { href: (props.Type === "Article" ? props.Url : props.Link) || undefined },
                React.createElement(AdaptiveImageComponent, { large: props.ImageUrlLarge, medium: props.ImageUrlMedium, small: props.ImageUrlSmall, alt: props.ImageAlt, className: styles.image }))),
            props.Type === "Video" && (React.createElement(CloudinaryVideoPlayer, { publicId: props.CloudinaryVideoPublicID || "", logoImageUrl: ((_a = props.startPageData.logo.value) === null || _a === void 0 ? void 0 : _a.url) || "", showControls: false, autoplay: isAutoplay, loop: isLoop, resetVideo: resetOnEnd, thumbnailUrl: defaultPosterUrl || undefined, withOverlay: true, forcePlay: forceVideoPlay, setForcePlay: setForceVideoPlay }))),
        React.createElement(Col, { sm: props.sideCard ? 4 : 12, className: cn(styles.newsItemSection, props.sideCard && styles.sideCardNewsItemSection) },
            (props.Type === "Article" || props.Type === "CrossReference") && (React.createElement("span", { className: styles.category }, (_c = (_b = props.Categories) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.Name)),
            props.Type === "Event" && (React.createElement("span", { className: styles.category }, props.DateTo ? (React.createElement(React.Fragment, null,
                React.createElement("span", null,
                    React.createElement("span", { className: styles.nonBreakingSpan }, props.DateFrom),
                    React.createElement("span", { className: styles.nonBreakingSpan },
                        " ",
                        "- ",
                        props.DateTo)),
                React.createElement("span", null, props.Location))) : (React.createElement("span", null,
                props.DateFrom,
                " | ",
                props.Location)))),
            React.createElement("h4", { className: styles.h4 }, props.Title),
            props.sideCard && (React.createElement("div", { className: styles.descriptionBox }, props.Type === "CrossReference" ? (React.createElement(RichTextWithTypographyRenderer, { value: props.Description || "" })) : (React.createElement("p", { className: styles.textDefault }, props.Description)))),
            React.createElement("div", { className: cn(styles.buttons, props.sideCard && styles.sideCardButtons) },
                props.Type === "Article" && (React.createElement(Button, { type: "link", href: props.Url },
                    React.createElement(LocalizedLabel, { section: "Global", label: "ReadMore" }))),
                (props.Type === "CrossReference" || props.Type === "Event") &&
                    props.Link && (React.createElement(Button, { type: isFile(props.Link) && !isExternal(props.Link)
                        ? "primary"
                        : "secondary", href: props.Link, title: props.LinkText || undefined, target: "_blank" }, props.LinkText)),
                props.Type === "Video" && (React.createElement(Button, { type: "secondary", onClick: function () { return setForceVideoPlay(true); }, icon: "play" },
                    React.createElement(LocalizedLabel, { section: "Global", label: "PlayVideo" })))))));
}
var useCardStyles = createUseStyles({
    newsItem: __assign({ height: "100%", display: "flex", backgroundColor: function (isThemeHomepage) {
            return isThemeHomepage ? brandColors.ultraLightGrey : brandColors.white;
        } }, onBreakpoint("sm", {
        flexDirection: "column",
        paddingBottom: rem(spacings.m),
    })),
    newsItemSection: __assign({ flex: 1, height: "100%", display: "flex", flexDirection: "column", padding: rem(spacings.s) }, onBreakpoint("sm", {
        padding: "".concat(spacings.m, " ").concat(spacings.m, " 0 ").concat(spacings.m),
    })),
    h4: customTypography(typography.h4, { marginBottom: 0 }, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.m) }),
    category: __assign(__assign({}, typography.caption), { display: "flex", flexDirection: "column", marginBottom: rem(spacings.xs) }),
    imageContainer: __assign({ overflow: "hidden" }, onBefore("sm", {
        flex: 1,
    })),
    image: {
        transform: "none",
        transition: "transform 0.3s ease",
        "&:hover": {
            transform: "scale(1.03)",
            transition: "transform 0.3s ease",
        },
    },
    teaserImageLink: {
        position: "relative",
        display: "block",
        paddingTop: "62%",
        height: "100%",
    },
    teaserImage: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        objectFit: "cover",
    },
    buttons: __assign({ display: "none", marginTop: "auto" }, onBreakpoint("sm", {
        display: "block",
    })),
    nonBreakingSpan: {
        whiteSpace: "nowrap",
        //fix for Firefox
        "@supports (-moz-appearance:none)": {
            whiteSpace: "normal",
        },
        //fix for Safari
        "@media not all and (min-resolution:.001dpcm)": {
            whiteSpace: "normal",
        },
    },
});
export function NewsWallTeaserCard(props) {
    var _a, _b, _c;
    var styles = useCardStyles(props.theme === "homepage");
    var _d = useState(false), forceVideoPlay = _d[0], setForceVideoPlay = _d[1];
    var isAutoplay = props.Type === "Video" && props.StartAction === "autoplay";
    var isLoop = props.Type === "Video" && props.EndAction === "loop_video";
    var resetOnEnd = props.Type === "Video" && props.EndAction === "reset_to_thumbnail";
    var defaultPosterUrl = props.Type === "Video" &&
        (props.ImageUrlLarge || props.ImageUrlMedium || props.ImageUrlSmall);
    return (React.createElement("div", { className: styles.newsItem, "data-testid": "NewsWallTeaserCard" },
        React.createElement("div", { className: styles.imageContainer },
            (props.Type === "Article" || props.Type === "CrossReference") &&
                (props.ImageUrlLarge ||
                    props.ImageUrlMedium ||
                    props.ImageUrlSmall) && (React.createElement("a", { href: (props.Type === "Article" ? props.Url : props.Link) || undefined },
                React.createElement(AdaptiveImageComponent, { large: props.ImageUrlLarge, medium: props.ImageUrlMedium, small: props.ImageUrlSmall, alt: props.ImageAlt, className: styles.image }))),
            props.Type === "Video" && (React.createElement(CloudinaryVideoPlayer, { publicId: props.CloudinaryVideoPublicID || "", logoImageUrl: ((_a = props.startPageData.logo.value) === null || _a === void 0 ? void 0 : _a.url) || "", showControls: false, autoplay: isAutoplay, loop: isLoop, resetVideo: resetOnEnd, thumbnailUrl: defaultPosterUrl || undefined, withOverlay: true, forcePlay: forceVideoPlay, setForcePlay: setForceVideoPlay }))),
        React.createElement("div", { className: styles.newsItemSection },
            (props.Type === "Article" || props.Type === "CrossReference") && (React.createElement("span", { className: styles.category }, (_c = (_b = props.Categories) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.Name)),
            props.Type === "Event" && (React.createElement("span", { className: styles.category }, props.DateTo ? (React.createElement(React.Fragment, null,
                React.createElement("span", null,
                    React.createElement("span", { className: styles.nonBreakingSpan }, props.DateFrom),
                    React.createElement("span", { className: styles.nonBreakingSpan },
                        " ",
                        "- ",
                        props.DateTo)),
                React.createElement("span", null, props.Location))) : (React.createElement("span", null,
                React.createElement("span", null,
                    props.DateFrom,
                    " | ",
                    props.Location))))),
            React.createElement("h4", { className: styles.h4 }, props.Title),
            React.createElement("div", { className: styles.buttons },
                props.Type === "Article" && (React.createElement(Button, { type: "link", href: props.Url },
                    React.createElement(LocalizedLabel, { section: "Global", label: "ReadMore" }))),
                (props.Type === "CrossReference" || props.Type === "Event") &&
                    props.Link && (React.createElement(Button, { type: isFile(props.Link) && !isExternal(props.Link)
                        ? "primary"
                        : "secondary", href: props.Link, title: props.LinkText || undefined, target: "_blank" }, props.LinkText)),
                props.Type === "Video" && (React.createElement(Button, { type: "secondary", onClick: function () { return setForceVideoPlay(true); }, icon: "play" },
                    React.createElement(LocalizedLabel, { section: "Global", label: "PlayVideo" })))))));
}
