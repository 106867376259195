var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, MAX_LAYOUT_WIDTH, Row, getGridColumnWidth, onBefore, onBreakpoint, rem, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { isDeviceResOrLower, useDeviceType } from "@hooks/useDeviceType";
import { FeaturedContent } from "./FeaturedContent";
import { FeaturedContentReversed } from "./FeaturedContentReversed/FeaturedContentReversed";
var useStyles = createUseStyles({
    cardColumn: __assign(__assign({ display: "flex", justifyContent: "center", marginBottom: rem(spacings.l) }, onBreakpoint("sm", {
        marginBottom: "calc(".concat(getGridColumnWidth("sm"), " + ").concat(spacings.xl, ")"),
    })), onBreakpoint("md", {
        marginBottom: "calc(".concat(getGridColumnWidth("md"), " + ").concat(spacings.xxl, ")"),
    })),
    centerItem: __assign(__assign({ position: "relative" }, onBefore("sm", {
        display: "flex",
        flexDirection: "column",
    })), { width: "calc(min(100vw, ".concat(MAX_LAYOUT_WIDTH, "px))") }),
});
export function FeaturedContentBlock(props) {
    var styles = useStyles();
    var _a = useContext(GlobalContentStoreContext), getStartPageData = _a.getStartPageData, getImmediateStartPageData = _a.getImmediateStartPageData;
    var _b = useState(getImmediateStartPageData()), startPageData = _b[0], setStartPageData = _b[1];
    var deviceType = useDeviceType();
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    if (!startPageData)
        return null;
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    return (React.createElement(Container, { isFluid: true, dataTestid: "FeaturedContentBlock" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 12, className: styles.cardColumn, dataTestid: "FeaturedElementCard" },
                React.createElement("div", { className: styles.centerItem }, props.data.reverseLayout.value === true && !isMobile ? (React.createElement(FeaturedContentReversed, __assign({}, props, { startPageData: startPageData }))) : (React.createElement(FeaturedContent, __assign({}, props, { startPageData: startPageData }))))))));
}
