var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useState, useEffect } from "react";
import { Header } from "@components/Header/Header";
import { Footer } from "@components/Shared/Footer";
import { AnimatedBlockRenderProperty, RenderProperty, } from "@/src/views/RenderProperty";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { Container, Row, Col, rem, onBreakpoint } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { typography } from "@basics/typography";
import { spacings } from "@basics/spacings";
import { TagBadge } from "@components/TagBadge/TagBadge";
import { BreadcrumbComponent } from "@components/BreadcrumbComponent/BreadcrumbComponent";
import { RelatedContentBlock } from "@components/Block/RelatedContentBlock";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import { DynamicDownloadBlock } from "@components/Block/DynamicDownloadBlock";
import { namePropertyValue } from "@models/utils";
import { fonts } from "@basics/fonts";
var useStyles = createUseStyles({
    header: __assign(__assign({ paddingTop: rem(spacings.m), marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    headline: typography.h1,
    articleInfo: __assign(__assign({}, typography.caption), { marginBottom: rem(spacings.sam) }),
    spacer: {
        margin: "0, ".concat(spacings.s),
    },
    tags: {
        display: "flex",
        flexWrap: "wrap",
    },
    textColumn: __assign({ order: 1, marginTop: rem(spacings.l) }, onBreakpoint("sm", {
        order: 0,
        marginTop: 0,
    })),
    mobileAndTabletOnly: __assign({ display: "block" }, onBreakpoint("md", {
        display: "none",
    })),
    desktopOnly: __assign({ display: "none" }, onBreakpoint("md", {
        display: "flex",
    })),
    description: __assign({ extend: typography.textDefault }, fonts.book),
});
export function NewsArticlePage(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = useContext(GlobalContentStoreContext), getStartPageData = _l.getStartPageData, getImmediateStartPageData = _l.getImmediateStartPageData;
    var _m = useState(getImmediateStartPageData()), startPageData = _m[0], setStartPageData = _m[1];
    var styles = useStyles();
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    if (!startPageData)
        return null;
    var contactExpertData = ((_a = props.data.contactsUseDefaultContent) === null || _a === void 0 ? void 0 : _a.value)
        ? startPageData
        : props.data;
    var Spacer = React.createElement("span", { className: styles.spacer }, "\u00A0|\u00A0");
    var image = (_b = props.data.headerImageAdaptive) === null || _b === void 0 ? void 0 : _b.value;
    var hasImage = (image === null || image === void 0 ? void 0 : image.urlLarge) || (image === null || image === void 0 ? void 0 : image.urlMedium) || (image === null || image === void 0 ? void 0 : image.urlSmall);
    var showDescription = props.data.showDescription.value || false;
    var newsWallPageUrl = (_d = (_c = startPageData.newsWallPage) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.url;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, __assign({}, startPageData)),
        React.createElement("main", { "data-testid": "NewsArticlePage" },
            React.createElement(Container, null,
                React.createElement(Row, { className: styles.header },
                    React.createElement(Col, { className: styles.mobileAndTabletOnly },
                        React.createElement(BreadcrumbComponent, null)),
                    React.createElement(Col, { sm: hasImage ? 6 : 12, md: hasImage ? 7 : 12, className: styles.textColumn },
                        React.createElement(BreadcrumbComponent, { className: styles.desktopOnly }),
                        React.createElement("h1", { className: styles.headline },
                            React.createElement(RenderProperty, { value: props.data.title, fallbackValue: props.data.name })),
                        React.createElement("div", { className: styles.articleInfo },
                            React.createElement(RenderProperty, { value: props.data.dateFormatted }),
                            ((_e = props.data.location) === null || _e === void 0 ? void 0 : _e.value) && Spacer,
                            React.createElement(RenderProperty, { value: props.data.location }),
                            ((_f = props.data.author) === null || _f === void 0 ? void 0 : _f.value) && Spacer,
                            React.createElement("a", { href: "".concat(newsWallPageUrl, "?author=").concat((_g = props.data.author) === null || _g === void 0 ? void 0 : _g.value) ||
                                    "" },
                                React.createElement(RenderProperty, { value: props.data.author }))),
                        React.createElement("div", null, showDescription && props.data.description && (React.createElement(AnimatedBlockRenderProperty, { value: props.data.description, className: styles.description, revealDelay: 100, movement: false }))),
                        React.createElement("div", { className: styles.tags }, (_h = props.data.visibleTags.expandedValue) === null || _h === void 0 ? void 0 : _h.map(function (item) {
                            var _a;
                            return (React.createElement(TagBadge, { isReadOnly: true, label: namePropertyValue(item.name) || "", key: item.contentLink.id, href: "".concat((_a = props.data.newsWallLinkExpandedProperty.value) === null || _a === void 0 ? void 0 : _a.url, "?tag=").concat(item.contentLink.id) }));
                        }))),
                    hasImage && (React.createElement(Col, { sm: 6, md: 5 },
                        React.createElement(RenderProperty, { value: props.data.headerImageAdaptive }))))),
            React.createElement(RenderProperty, { value: props.data.content }),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            React.createElement(DynamicDownloadBlock, { downloadCategoriesFilter: props.data.downloadCategoriesFilter, downloadsManual: props.data.downloadsManual }),
            ((_j = props.data.contactCta) === null || _j === void 0 ? void 0 : _j.value) === "enabled" && (React.createElement(ContactExpert, __assign({}, contactExpertData))),
            ((_k = props.data.relatedContent) === null || _k === void 0 ? void 0 : _k.value) && (React.createElement(RelatedContentBlock, { relatedContent: props.data.relatedContent }))),
        React.createElement(Footer, __assign({}, startPageData))));
}
