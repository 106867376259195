var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useState, useEffect, useRef, useCallback, } from "react";
import { Header } from "@components/Header/Header";
import { Footer } from "@components/Shared/Footer";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { Container, Row, Col, onBreakpoint, rem, onBefore, CONTAINER_OFFSET_WIDTHS, } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { spacings } from "@basics/spacings";
import { typography, customTypography } from "@basics/typography";
import { Button, ButtonWrapper } from "@components/Button/Button";
import { RenderProperty } from "@/src/views/RenderProperty";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { LocalizedLabel, localizedLabelString, LocalizationContext, } from "@hooks/LocalizationContext";
import { useDeviceType, isDeviceResOrLower } from "@hooks/useDeviceType";
import { Input } from "@components/Input/Input";
import { Checkbox } from "@components/Checkbox/Checkbox";
import { Spinner } from "@components/Spinner/Spinner";
import { Select } from "@components/Select/Select";
import { AccordionItem, Accordion } from "@components/Accordion/Accordion";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import { getCurrentLocale } from "@basics/locales";
import { Helmet } from "react-helmet";
import cn from "classnames";
import { DynamicDownloadBlock } from "@components/Block/DynamicDownloadBlock";
import { RichTextWithTypographyRenderer } from "@components/Block/RichTextBlock/RichTextBlock";
import { brandColors } from "@basics/brand-colors";
var PAGE_SIZE = 30;
var LOCALE_ALL_VALUE = "en";
export function getJobData(page, language, contentLink, query, careerLevel, workTime, company, pageLanguage) {
    var queryParameters = new URLSearchParams();
    query && queryParameters.set("q", query);
    queryParameters.set("rootContentLink", (contentLink === null || contentLink === void 0 ? void 0 : contentLink.id.toString()) || "");
    language &&
        language !== LOCALE_ALL_VALUE &&
        queryParameters.set("language", language);
    queryParameters.set("page", page.toString());
    queryParameters.set("size", PAGE_SIZE.toString());
    queryParameters.set("career_level", Array.from(careerLevel).filter(Boolean).join(","));
    queryParameters.set("work_time", Array.from(workTime).filter(Boolean).join(","));
    queryParameters.set("company", Array.from(company).filter(Boolean).join(","));
    pageLanguage && queryParameters.set("pagelanguage", pageLanguage);
    return fetch("/api/hdiglobal/job-listing/results?".concat(queryParameters)).then(function (r) { return r.json(); });
}
export function switchSetOption(set, option, options) {
    var newSet = new Set(set);
    if (!set.has(option)) {
        options === null || options === void 0 ? void 0 : options.forEach(function (checkbox) {
            return newSet.add(checkbox.contentLink.id.toString());
        });
        return newSet.add(option);
    }
    options &&
        (options === null || options === void 0 ? void 0 : options.forEach(function (item) {
            return newSet.delete(item.contentLink.id.toString());
        }));
    newSet.delete(option);
    return newSet;
}
var useStyles = createUseStyles({
    element: __assign(__assign({ paddingTop: rem(spacings.m), paddingBottom: rem(spacings.l) }, onBreakpoint("sm", {
        paddingTop: rem(spacings.l),
        paddingBottom: rem(spacings.xl),
    })), onBreakpoint("md", {
        paddingTop: rem(spacings.xl),
        paddingBottom: rem(spacings.xxl),
    })),
    elementNoPaddingTop: __assign(__assign({ paddingTop: 0 }, onBreakpoint("sm", {
        paddingTop: 0,
    })), onBreakpoint("md", {
        paddingTop: 0,
    })),
    additionalTopContent: __assign(__assign({ marginTop: rem(spacings.m) }, onBreakpoint("sm", {
        marginTop: rem(spacings.l),
    })), onBreakpoint("md", {
        marginTop: rem(spacings.xl),
    })),
    header: typography.h1,
    results: {
        marginTop: rem(spacings.m),
    },
    resultsText: __assign(__assign({}, customTypography(typography.textLarge, { marginBottom: 0 })), { flex: 3 }),
    autocorrectionText: typography.textDefault,
    autocorrectionLink: {
        textDecoration: "underline",
    },
    contentRow: onBreakpoint("sm", {
        gridTemplateRows: "auto auto 1fr",
    }),
    searchBarColumn: {
        gridRow: 2,
    },
    articleCardsColumn: __assign({ gridRow: 4 }, onBreakpoint("sm", {
        gridRow: 3,
    })),
    filtersColumn: __assign({ gridRow: 3 }, onBreakpoint("sm", {
        gridRow: 1,
        gridRowEnd: "span 3",
    })),
    flexBar: {
        display: "flex",
        alignItems: "center",
    },
    queryBar: __assign(__assign({}, onBefore("sm", { marginTop: rem(spacings.m) })), { display: "flex" }),
    filtersBackground: onBefore("sm", {
        zIndex: -1,
        position: "absolute",
        height: "100%",
        width: "100vw",
        backgroundColor: brandColors.ultraLightGrey,
        marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
    }),
    moreButton: {
        display: "flex",
        justifyContent: "center",
        paddingTop: rem(spacings.m),
    },
    infoBannerColumn: {
        gridRow: 1,
    },
    infoBanner: __assign({ display: "flex", alignItems: "center", backgroundColor: brandColors.yellowOchre, padding: "".concat(spacings.s), marginBottom: rem(spacings.sam) }, onBreakpoint("md", {
        padding: rem(spacings.m),
        marginBottom: rem(spacings.m),
    })),
    infoBannerContent: __assign(__assign({ marginLeft: rem(spacings.s) }, onBreakpoint("sm", {
        marginLeft: rem(spacings.sam),
    })), { "& *": {
            color: "".concat(brandColors.white, " !important"),
            marginBottom: "0 !important",
        } }),
    infoBannerButton: {
        background: "none",
        marginLeft: "auto",
        alignSelf: "flex-start",
        padding: 0,
    },
    infoBannerButtons: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: rem(spacings.sam),
        "& > *": {
            marginBottom: "".concat(rem(spacings.xs), " !important"),
            "&:last-child": {
                marginBottom: "0 !important",
            },
        },
    },
    infoBannerIcon: FAIconStyles(__assign({ height: 60, width: 60, color: brandColors.white }, onBefore("sm", {
        display: "none",
    }))),
    infoBannerClose: FAIconStyles({
        height: 32,
        width: 32,
        color: brandColors.white,
    }),
    visuallyHidden: __assign({}, typography.visuallyHidden),
});
export function JobListingPage(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var initialQueryParametrs = new URLSearchParams(window.location.search);
    var _k = useContext(GlobalContentStoreContext), getStartPageData = _k.getStartPageData, getImmediateStartPageData = _k.getImmediateStartPageData, getWebsiteData = _k.getWebsiteData;
    var localizationCtx = useContext(LocalizationContext);
    var _l = useState(getImmediateStartPageData()), startPageData = _l[0], setStartPageData = _l[1];
    var websiteData = getWebsiteData();
    var _m = useState(null), jobListingData = _m[0], setJobListingData = _m[1];
    var _o = useState(false), isLoading = _o[0], setLoading = _o[1];
    var _p = useState(0), page = _p[0], setPage = _p[1];
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var CAREER_LEVEL_OPTIONS = [
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "Graduates",
                localizationCtx: localizationCtx,
            }),
            value: "graduates",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "Professionals",
                localizationCtx: localizationCtx,
            }),
            value: "professionals",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "Trainees",
                localizationCtx: localizationCtx,
            }),
            value: "trainees",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "WorkingStudent",
                localizationCtx: localizationCtx,
            }),
            value: "working-students",
        },
    ];
    var WORK_TIME_OPTIONS = [
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "FullTime",
                localizationCtx: localizationCtx,
            }),
            value: "full-time",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "FullPartTime",
                localizationCtx: localizationCtx,
            }),
            value: "full-part-time",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "PartTime",
                localizationCtx: localizationCtx,
            }),
            value: "part-time",
        },
    ];
    var LOCALE_OPTIONS = __spreadArray([
        {
            displayName: localizedLabelString({
                section: "JobListing",
                label: "ShowAll",
                localizationCtx: localizationCtx,
            }),
            name: LOCALE_ALL_VALUE,
            countryDisplayName: "",
        }
    ], (((_a = props.data.existingLanguages) === null || _a === void 0 ? void 0 : _a.filter(function (co) { return co.name !== LOCALE_ALL_VALUE; })) || []), true);
    var COMPANY_OPTIONS = [
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiGlobal",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-global",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiSpecialty",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-specialty",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiInsurance",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-insurance",
        },
    ];
    var _q = useState(new Set(initialQueryParametrs.has("careerlevel")
        ? decodeURI(initialQueryParametrs.get("careerlevel") || "").split(",")
        : [])), careerLevels = _q[0], setCareerLevels = _q[1];
    var _r = useState(new Set(initialQueryParametrs.has("worktimemodel")
        ? decodeURI(initialQueryParametrs.get("worktimemodel") || "").split(",")
        : [])), workTimeModels = _r[0], setWorkTimeModels = _r[1];
    var _s = useState(new Set(initialQueryParametrs.has("company")
        ? decodeURI(initialQueryParametrs.get("company") || "").split(",")
        : [])), company = _s[0], setCompany = _s[1];
    var _t = useState(getCurrentLocale(websiteData, initialQueryParametrs)), localeSelection = _t[0], setLocaleSelection = _t[1];
    var pageLanguage = (websiteData && websiteData.currentLanguage.link) || undefined;
    var styles = useStyles();
    //Regarding ticket HGEPI-1156
    var FILTERED_LOCALE_OPTIONS = LOCALE_OPTIONS.filter(function (l) { return l.displayName !== "Ireland"; }).map(function (l) {
        return l.displayName === "United Kingdom"
            ? __assign(__assign({}, l), { displayName: "UK and Ireland", name: localeSelection === "en-gb" ? "en-gb" : "en-ie" }) : l;
    });
    var _u = useState(decodeURI(initialQueryParametrs.get("query") || "")), query = _u[0], setQuery = _u[1];
    var queryInputRef = useRef(null);
    var loadItems = useCallback(function (requestedPage) {
        var apiPage = requestedPage || page + 1;
        setLoading(true);
        return getJobData(apiPage, localeSelection, props.data.contentLink, query, careerLevels, workTimeModels, company, pageLanguage).then(function (response) {
            setPage(apiPage);
            setLoading(false);
            return response;
        });
    }, [
        careerLevels,
        company,
        localeSelection,
        page,
        pageLanguage,
        props.data.contentLink,
        query,
        workTimeModels,
    ]);
    function searchFunction() {
        var _a;
        setQuery(((_a = queryInputRef.current) === null || _a === void 0 ? void 0 : _a.value) || "");
    }
    function clearQuery() {
        setQuery("");
    }
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (startPageResolved) {
                setStartPageData(startPageResolved);
            });
    }, [getStartPageData, startPageData]);
    useEffect(function () {
        var queryParameters = new URLSearchParams();
        careerLevels.size &&
            queryParameters.set("careerlevel", encodeURI(Array.from(careerLevels.values()).join(",")));
        workTimeModels.size &&
            queryParameters.set("worktimemodel", encodeURI(Array.from(workTimeModels.values()).join(",")));
        company.size &&
            queryParameters.set("company", encodeURI(Array.from(company.values()).join(",")));
        localeSelection && queryParameters.set("locale", localeSelection);
        queryParameters.set("query", encodeURI(query));
        websiteData &&
            queryParameters.set("pagelanguage", websiteData.currentLanguage.link || "");
        setJobListingData(null);
        history.replaceState({}, "", "".concat(window.location.pathname, "?").concat(queryParameters));
        loadItems(1).then(function (response) { return response && setJobListingData(response); });
    }, [
        startPageData,
        careerLevels,
        workTimeModels,
        company,
        localeSelection,
        query,
        pageLanguage,
        websiteData,
        loadItems,
    ]);
    var maybeInfoBanner = props.data.infoBanner
        .expandedValue;
    var _v = useState(false), isInfoBannerClosed = _v[0], setInfoBannerClosed = _v[1];
    var isUserRetention = ((_b = props.data.contactCta) === null || _b === void 0 ? void 0 : _b.value) === "enabled";
    if (!startPageData || !websiteData)
        return null;
    var contactExpertData = ((_c = props.data.contactsUseDefaultContent) === null || _c === void 0 ? void 0 : _c.value)
        ? startPageData
        : props.data;
    var isInternational = localeSelection === LOCALE_ALL_VALUE;
    function buildJobDescription(job, isInternational) {
        var _a;
        var jobDescriptionLabel = localizedLabelString({
            section: "JobListing",
            label: "JobDescriptionLabel",
            localizationCtx: localizationCtx,
        });
        var jobDescriptionLinkTitle = localizedLabelString({
            section: "JobListing",
            label: "JobDescriptionLinkTitle",
            localizationCtx: localizationCtx,
        });
        var jobDescription = (_a = (isInternational ? job.JobDescriptionEnglish : job.JobDescriptionLocal)) !== null && _a !== void 0 ? _a : "";
        return "<p>".concat(jobDescription, "<br />").concat(jobDescriptionLabel, "<a href=\"https://www.hdi.global").concat(job.JobPosting, "\" title=\"").concat(jobDescriptionLinkTitle, "\">").concat(jobDescriptionLinkTitle, "</a></p>");
    }
    var isAdditionalTopContent = !!((_d = props.data.additionalTopContent) === null || _d === void 0 ? void 0 : _d.expandedValue);
    return (React.createElement(React.Fragment, null,
        jobListingData && (React.createElement(Helmet, null,
            React.createElement("script", { type: "application/ld+json" }, "\n            ".concat(JSON.stringify((_e = jobListingData === null || jobListingData === void 0 ? void 0 : jobListingData.Hits) === null || _e === void 0 ? void 0 : _e.map(function (result) {
                var _a, _b, _c;
                return (__assign(__assign(__assign(__assign({ "@context": "https://schema.org/", "@type": "JobPosting", title: isInternational
                        ? result.JobTitleEnglish
                        : result.JobTitleLocal, description: buildJobDescription(result, isInternational), jobLocation: {
                        "@type": "Place",
                        address: {
                            "@type": "PostalAddress",
                            streetAddress: result.Address.Street,
                            addressLocality: result.Address.City,
                            addressRegion: result.Address.Region,
                            postalCode: result.Address.Region,
                            addressCountry: result.Address.Country,
                        },
                    }, validThrough: result.ValidThrough, hiringOrganization: {
                        "@type": "Organization",
                        name: "HDI Global SE",
                        sameAs: "https://www.hdi.global",
                        logo: "https://www.hdi.global/".concat((_a = startPageData.logo.value) === null || _a === void 0 ? void 0 : _a.url),
                        address: {
                            addressCountry: "Germany",
                            postalCode: "30659",
                            streetAddress: "HDI-Platz 1",
                            addressLocality: "Hannover",
                        },
                    }, baseSalary: {
                        "@type": "MonetaryAmount",
                        currency: result.SalaryCurrency,
                        value: {
                            "@type": "QuantitativeValue",
                            value: result.SalaryBase,
                            unitText: result.SalaryPeriodicity,
                        },
                    } }, (result.WorkTime
                    ? {
                        workHours: (_b = WORK_TIME_OPTIONS.find(function (i) { return i.value === result.WorkTime; })) === null || _b === void 0 ? void 0 : _b.label,
                    }
                    : {})), (result.StartingDate
                    ? { jobStartDate: result.StartingDate }
                    : {})), (result.CareerLevel
                    ? {
                        employmentType: (_c = CAREER_LEVEL_OPTIONS.find(function (i) { return i.value === result.CareerLevel; })) === null || _c === void 0 ? void 0 : _c.label,
                    }
                    : {})), (result.PostingDate
                    ? { datePosted: result.PostingDate }
                    : {})));
            })), "\n          ")))),
        React.createElement(Header, __assign({}, startPageData)),
        React.createElement("main", { "data-testid": "JobListingPage" },
            isAdditionalTopContent && (React.createElement(RenderProperty, { value: props.data.additionalTopContent, className: styles.additionalTopContent })),
            React.createElement(Container, { className: cn(styles.element, isAdditionalTopContent && styles.elementNoPaddingTop) },
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 7, md: 8 },
                        React.createElement("h1", { className: styles.header },
                            React.createElement(RenderProperty, { value: localizedLabelString({
                                    section: "JobListing",
                                    label: "JobPostings",
                                    localizationCtx: localizationCtx,
                                }) })))),
                React.createElement(Row, { className: styles.contentRow },
                    React.createElement(Col, { xs: 4, sm: 4, md: 3, className: styles.filtersColumn },
                        React.createElement("div", { className: styles.filtersBackground }),
                        React.createElement(FiltersElement, { careerLevelSelections: careerLevels, setCareerLevelSelections: function (changeFunc) {
                                return setCareerLevels(function (state) { return changeFunc(state); });
                            }, workTimeModelsSelections: workTimeModels, setWorkTimeModelsSelections: function (changeFunc) {
                                return setWorkTimeModels(function (state) { return changeFunc(state); });
                            }, companySelections: company, setCompanySelections: function (changeFunc) { return setCompany(function (state) { return changeFunc(state); }); }, localeSelection: localeSelection, setLocaleSelection: setLocaleSelection, careerLevelOptions: CAREER_LEVEL_OPTIONS, workTimeModelOptions: WORK_TIME_OPTIONS, companyOptions: COMPANY_OPTIONS, localeOptions: FILTERED_LOCALE_OPTIONS, teaserContent: props.data.teaserContent, enableLocalFilter: (_f = props.data.enableLocalFilter) === null || _f === void 0 ? void 0 : _f.value, enableCompanyFilter: (_g = props.data.enableCompanyFilter) === null || _g === void 0 ? void 0 : _g.value })),
                    React.createElement(Col, { sm: 8, smStart: 4, md: 9, mdStart: 3, className: styles.infoBannerColumn }, maybeInfoBanner && !isInfoBannerClosed && (React.createElement("div", { className: styles.infoBanner },
                        React.createElement(FAIcon, { icon: "info-circle", className: styles.infoBannerIcon }),
                        React.createElement("div", { className: styles.infoBannerContent },
                            React.createElement(RenderProperty, { value: localeSelection === LOCALE_ALL_VALUE
                                    ? maybeInfoBanner.bannerTextEnglish
                                    : maybeInfoBanner.bannerTextLocal }),
                            React.createElement("div", { className: styles.infoBannerButtons }, (_h = maybeInfoBanner.buttons.value) === null || _h === void 0 ? void 0 : _h.map(function (b, idx) { return (React.createElement(ButtonWrapper, __assign({}, b, { key: idx, buttonType: "link" }))); }))),
                        React.createElement("button", { className: styles.infoBannerButton, onClick: function () { return setInfoBannerClosed(true); } },
                            React.createElement("span", { className: styles.visuallyHidden }, "(click to close)"),
                            React.createElement(FAIcon, { icon: "times", className: styles.infoBannerClose }))))),
                    React.createElement(Col, { xs: 4, sm: 8, smStart: 4, md: 6, mdStart: 3, className: styles.searchBarColumn },
                        React.createElement("div", { className: styles.filtersBackground }),
                        React.createElement("div", { className: styles.queryBar },
                            React.createElement(Input, { label: localizedLabelString({
                                    section: "Search",
                                    label: "Search",
                                    localizationCtx: localizationCtx,
                                }), initialValue: query, inputRef: queryInputRef, onSubmit: searchFunction, clearQuery: clearQuery, theme: isMobile ? "white" : "gray" }),
                            React.createElement(Button, { type: "primary", onClick: searchFunction },
                                React.createElement(LocalizedLabel, { section: "Search", label: "Search" })))),
                    React.createElement(Col, { xs: 4, sm: 8, smStart: 4, md: 9, mdStart: 3, className: styles.articleCardsColumn },
                        React.createElement("div", { className: styles.results },
                            isLoading && React.createElement(Spinner, null),
                            jobListingData && (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: styles.flexBar },
                                    React.createElement("p", { className: styles.resultsText },
                                        React.createElement("strong", null, jobListingData === null || jobListingData === void 0 ? void 0 : jobListingData.TotalCount),
                                        "\u00A0",
                                        React.createElement(LocalizedLabel, { section: "Search", label: "Results" }),
                                        (jobListingData === null || jobListingData === void 0 ? void 0 : jobListingData.Filter.Q) && (React.createElement(React.Fragment, null,
                                            "\u00A0",
                                            React.createElement(LocalizedLabel, { section: "Search", label: "ForQuery" }),
                                            "\u00A0",
                                            React.createElement("strong", null,
                                                "\"", jobListingData === null || jobListingData === void 0 ? void 0 :
                                                jobListingData.Filter.Q,
                                                "\""))))),
                                (jobListingData === null || jobListingData === void 0 ? void 0 : jobListingData.Hits) && (React.createElement(AdSearchResults, { items: jobListingData.Hits, careerLevelOptions: CAREER_LEVEL_OPTIONS, workTimeOptions: WORK_TIME_OPTIONS, isInternational: isInternational, careerLevelSelections: careerLevels, setCareerLevelSelections: function (changeFunc) {
                                        return setCareerLevels(function (state) { return changeFunc(state); });
                                    }, workTimeModelsSelections: workTimeModels, setWorkTimeModelsSelections: function (changeFunc) {
                                        return setWorkTimeModels(function (state) { return changeFunc(state); });
                                    }, companySelections: company, setCompanySelections: function (changeFunc) {
                                        return setCompany(function (state) { return changeFunc(state); });
                                    }, enableCompanyFilter: (_j = props.data.enableCompanyFilter) === null || _j === void 0 ? void 0 : _j.value })))))),
                    jobListingData && jobListingData.TotalCount > page * PAGE_SIZE && (React.createElement(Col, { className: styles.moreButton }, isLoading ? (React.createElement(Spinner, null)) : (React.createElement(Button, { type: "secondary", onClick: function () {
                            loadItems().then(function (response) {
                                return response &&
                                    setJobListingData(function (s) {
                                        return s
                                            ? __assign(__assign({}, s), { Hits: __spreadArray(__spreadArray([], s.Hits, true), (response.Hits || []), true) }) : response;
                                    });
                            });
                        } },
                        React.createElement(LocalizedLabel, { section: "Search", label: "LoadMore" }))))))),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            React.createElement(DynamicDownloadBlock, { downloadCategoriesFilter: props.data.downloadCategoriesFilter, downloadsManual: props.data.downloadsManual }),
            isUserRetention && React.createElement(ContactExpert, __assign({}, contactExpertData))),
        React.createElement(Footer, __assign({}, startPageData))));
}
var useFiltersStyles = createUseStyles({
    mainElement: __assign({ marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.m),
    })),
    teaserContentItem: {
        "& > div": __assign({ marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
            marginBottom: rem(spacings.m),
        })),
    },
});
function FiltersElement(props) {
    var localizationCtx = useContext(LocalizationContext);
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var styles = useFiltersStyles();
    var localeOptions = props.localeOptions.map(function (lo) { return ({
        label: lo.displayName,
        value: lo.name,
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement(Accordion, { noOuterBorder: isMobile, className: styles.mainElement },
            props.enableLocalFilter && (React.createElement(AccordionItem, { headline: localizedLabelString({
                    section: "JobListing",
                    label: "Country",
                    localizationCtx: localizationCtx,
                }), initialIsActive: !isMobile },
                React.createElement(Select, { label: localizedLabelString({
                        section: "JobListing",
                        label: "Country",
                        localizationCtx: localizationCtx,
                    }), options: localeOptions, selected: props.localeSelection || "", setSelected: props.setLocaleSelection }))),
            props.enableCompanyFilter && (React.createElement(AccordionItem, { headline: localizedLabelString({
                    section: "JobListing",
                    label: "Company",
                    localizationCtx: localizationCtx,
                }), initialIsActive: !isMobile }, props.companyOptions.map(function (option) { return (React.createElement(Checkbox, { key: option.value, onCheck: function () {
                    return props.setCompanySelections(function (s) {
                        return switchSetOption(s, option.value);
                    });
                }, checked: props.companySelections.has(option.value), label: option.label })); }))),
            React.createElement(AccordionItem, { headline: localizedLabelString({
                    section: "JobListing",
                    label: "CareerLevel",
                    localizationCtx: localizationCtx,
                }), initialIsActive: !isMobile }, props.careerLevelOptions.map(function (option) { return (React.createElement(Checkbox, { key: option.value, onCheck: function () {
                    return props.setCareerLevelSelections(function (s) {
                        return switchSetOption(s, option.value);
                    });
                }, checked: props.careerLevelSelections.has(option.value), label: option.label })); })),
            React.createElement(AccordionItem, { headline: localizedLabelString({
                    section: "JobListing",
                    label: "WorkTimeModel",
                    localizationCtx: localizationCtx,
                }), initialIsActive: !isMobile }, props.workTimeModelOptions.map(function (option) { return (React.createElement(Checkbox, { key: option.value, onCheck: function () {
                    return props.setWorkTimeModelsSelections(function (s) {
                        return switchSetOption(s, option.value);
                    });
                }, checked: props.workTimeModelsSelections.has(option.value), label: option.label })); }))),
        React.createElement(RenderProperty, { value: props.teaserContent, theme: "filters", className: styles.teaserContentItem })));
}
var useAdStyles = createUseStyles({
    itemList: __assign({}, onBreakpoint("md", {
        "& $pageItem:first-child": {
            borderTop: "none",
        },
    })),
    singleItem: {
        display: "flex",
        padding: rem(spacings.sam),
        borderTop: "2px solid ".concat(brandColors.lightGrey),
    },
    h4: customTypography(typography.h4, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.sam) }),
    description: typography.textDefault,
    caption: typography.caption,
    fullWidth: {
        width: "100%",
    },
    tags: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: rem(spacings.s),
    },
    topLine: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    locationWrapper: {
        display: "flex",
        alignItems: "center",
    },
    countryFlag: {
        width: 24,
        height: 18,
        marginRight: rem(spacings.s),
    },
});
function AdSearchResults(props) {
    var localizationCtx = useContext(LocalizationContext);
    var styles = useAdStyles();
    return (React.createElement("div", { className: styles.itemList }, props.items.map(function (item, idx) {
        var _a, _b;
        var location = props.isInternational
            ? item.LocationEnglish
            : item.LocationLocal;
        var title = props.isInternational
            ? item.JobTitleEnglish
            : item.JobTitleLocal;
        var description = props.isInternational
            ? item.JobDescriptionEnglish
            : item.JobDescriptionLocal;
        var careerLevel = (_a = props.careerLevelOptions.find(function (opt) { return opt.value === item.CareerLevel; })) === null || _a === void 0 ? void 0 : _a.label;
        var workTimeModel = (_b = props.workTimeOptions.find(function (opt) { return opt.value === item.WorkTime; })) === null || _b === void 0 ? void 0 : _b.label;
        return (React.createElement(Container, { key: idx, className: styles.singleItem, dataTestid: "AdSearchResultCard" },
            React.createElement(Row, { className: styles.fullWidth },
                React.createElement(Col, null,
                    React.createElement("h4", { className: styles.h4 }, title),
                    description && (React.createElement(RichTextWithTypographyRenderer, { className: styles.description, value: description })),
                    (item.CareerLevel ||
                        item.WorkTime ||
                        item.StartingDate ||
                        item.Company) && (React.createElement("div", { className: styles.tags },
                        item.Company &&
                            props.enableCompanyFilter &&
                            item.Company.split(",").map(function (company, idx) {
                                var companyTranslationLabel;
                                switch (company) {
                                    case "hdi-global":
                                        companyTranslationLabel = "HdiGlobal";
                                        break;
                                    case "hdi-specialty":
                                        companyTranslationLabel = "HdiSpecialty";
                                        break;
                                    case "hdi-insurance":
                                        companyTranslationLabel = "HdiInsurance";
                                        break;
                                    default:
                                        return;
                                }
                                return (React.createElement(WhiteTagBadge, { icon: "globe", label: localizedLabelString({
                                        section: "JobListing",
                                        label: companyTranslationLabel,
                                        localizationCtx: localizationCtx,
                                    }), onClick: function () {
                                        return props.setCompanySelections(function (s) {
                                            return switchSetOption(s, company || "");
                                        });
                                    }, key: idx }));
                            }),
                        item.CareerLevel && careerLevel && (React.createElement(WhiteTagBadge, { icon: "user-tag", label: careerLevel, onClick: function () {
                                return props.setCareerLevelSelections(function (s) {
                                    return switchSetOption(s, item.CareerLevel || "");
                                });
                            } })),
                        item.WorkTime && workTimeModel && (React.createElement(WhiteTagBadge, { icon: "clock", label: workTimeModel, onClick: function () {
                                return props.setWorkTimeModelsSelections(function (s) {
                                    return switchSetOption(s, item.WorkTime || "");
                                });
                            } })),
                        item.StartingDate && (React.createElement(WhiteTagBadge, { icon: "calendar-plus", label: item.StartingDate })))),
                    React.createElement("div", { className: styles.topLine },
                        React.createElement("div", { className: styles.locationWrapper },
                            item.CountryFlag && (React.createElement("img", { className: styles.countryFlag, src: item.CountryFlag, alt: "Country flag" })),
                            React.createElement("span", { className: styles.caption }, location)),
                        React.createElement(Button, { type: "link", href: item.JobPosting, target: "_blank" },
                            React.createElement(LocalizedLabel, { section: "JobListing", label: "ExplorePosition" })))))));
    })));
}
var useWhiteTagStyles = createUseStyles({
    element: {
        marginRight: rem(spacings.xs),
        marginBottom: rem(spacings.xs),
        padding: "".concat(spacings.xxs, " ").concat(spacings.s, " ").concat(spacings.xxs, " ").concat(spacings.s),
        backgroundColor: brandColors.white,
        borderRadius: 30,
        border: "1px solid ".concat(brandColors.grey),
        display: "flex",
        alignItems: "center",
        color: brandColors.grey,
        textAlign: "left",
        cursor: "default",
    },
    icon: FAIconStyles({
        width: 24,
        height: 24,
        marginRight: rem(spacings.xs),
    }),
    label: __assign({}, customTypography(__assign(__assign({}, typography.textSmall), { whiteSpace: "nowrap", marginBottom: 0, color: "inherit" }), {}, {
        marginBottom: 0,
    })),
    hoverable: {
        "&$element:hover": {
            border: "1px solid ".concat(brandColors.black),
            color: brandColors.black,
            cursor: "pointer",
        },
    },
});
export function WhiteTagBadge(props) {
    var styles = useWhiteTagStyles();
    var isHoverable = props.onClick;
    return (React.createElement("button", { className: cn(styles.element, isHoverable && styles.hoverable), onClick: props.onClick, disabled: !isHoverable },
        React.createElement(FAIcon, { icon: props.icon, className: styles.icon }),
        React.createElement("b", { className: styles.label }, props.label)));
}
