var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { onBreakpoint } from "@basics/layout";
import { Checkbox } from "@components/Checkbox/Checkbox";
import React from "react";
import { createUseStyles } from "react-jss";
var useStyles = createUseStyles({
    checkboxSection: __assign(__assign({ display: "grid" }, onBreakpoint("sm", {
        gridTemplateColumns: "repeat(2, 1fr)",
    })), { gridTemplateColumns: "1fr" }),
});
export var ConsentOptionsSection = function (_a) {
    var _b;
    var consentOptions = _a.consentOptions, consentStateMap = _a.consentStateMap, setConsentStateMap = _a.setConsentStateMap;
    var styles = useStyles();
    return (React.createElement("div", { className: styles.checkboxSection }, (_b = consentOptions.value) === null || _b === void 0 ? void 0 : _b.map(function (consent) { return (React.createElement(Checkbox, { key: consent.consentId || "", label: consent.optionTitle || "", disabled: !!consent.isRequired, checked: !!consent.isRequired ||
            !!consentStateMap.get(consent.consentId || ""), onCheck: function () {
            return setConsentStateMap(function (s) {
                return new Map(s).set(consent.consentId || "", !s.get(consent.consentId || ""));
            });
        } })); })));
};
