import { RenderProperty } from "@/src/views/RenderProperty";
import { formattedPhoneNumber } from "@components/Block/ContactListBlock/PersonCard";
import { Button } from "@components/Button/Button";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import React from "react";
import { useStyles } from "./styles";
import { Icon } from "./Icon";
export var ContactDetails = function (props) {
    var vCardLink = props.vCardLink;
    var _a = props.contactPerson.expandedValue, company = _a.company, country = _a.country, email = _a.email, firstName = _a.firstName, lastName = _a.lastName, linkedInProfile = _a.linkedInProfile, phoneNumber = _a.phoneNumber, address = _a.address, jobTitle = _a.jobTitle;
    var _b = useStyles(), bioSection = _b.bioSection, companyDetails = _b.companyDetails, companyName = _b.companyName, countryName = _b.countryName, fullName = _b.fullName, icons = _b.icons, jobTitleDetails = _b.jobTitleDetails, addressDetails = _b.addressDetails;
    var getCompanyName = function () {
        switch (company.value) {
            case "hdi-global":
                return "HdiGlobal";
            case "hdi-specialty":
                return "HdiSpecialty";
            case "hdi-insurance":
                return "HdiInsurance";
            default:
                return "";
        }
    };
    var showIcons = email.value || phoneNumber.value || linkedInProfile.value;
    return (React.createElement("div", { className: bioSection },
        React.createElement(SemanticHeader, { headerSize: "h4", className: fullName },
            firstName && React.createElement(RenderProperty, { value: firstName }),
            " ",
            lastName && React.createElement(RenderProperty, { value: lastName })),
        jobTitle && (React.createElement(RenderProperty, { value: jobTitle, className: jobTitleDetails })),
        showIcons && (React.createElement("div", { className: icons },
            email.value && (React.createElement(Icon, { href: "mailto:".concat(email.value), iconName: "envelope", altText: "Write an email to" })),
            phoneNumber.value && (React.createElement(Icon, { href: "tel: ".concat(formattedPhoneNumber(phoneNumber.value)), iconName: "phone", altText: "Call to a phone number" })),
            linkedInProfile.value && (React.createElement(Icon, { href: linkedInProfile.value, iconName: "linkedin", iconPrefix: "fab", altText: "Go to LinkedIn profile" })))),
        React.createElement("div", { className: companyDetails },
            company.value && (React.createElement("span", { className: companyName },
                React.createElement(LocalizedLabel, { section: "JobListing", label: getCompanyName() }))),
            address.value && (React.createElement(RenderProperty, { value: address, className: addressDetails })),
            country.value && (React.createElement(RenderProperty, { value: country, className: countryName }))),
        vCardLink.value && (React.createElement(Button, { type: "primary", icon: "arrow-to-bottom", href: vCardLink.value },
            React.createElement(LocalizedLabel, { section: "BusinessCard", label: "SaveAsContactButton" })))));
};
