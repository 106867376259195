import React, { useContext, useEffect, useState } from "react";
import { Col } from "@basics/layout";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { useTracking } from "@hooks/useTracking";
import { RenderProperty, ShowPropertyOnEdit } from "@/src/views/RenderProperty";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { Button } from "@components/Button/Button";
import { FAIcon } from "@components/FAIcon/FAIcon";
import { personCardStyles } from "./styles";
export var GenericCard = function (props) {
    var _a, _b, _c;
    var _d = useContext(GlobalContentStoreContext), getStartPageData = _d.getStartPageData, getImmediateStartPageData = _d.getImmediateStartPageData;
    var _e = useState(getImmediateStartPageData()), startPageData = _e[0], setStartPageData = _e[1];
    var tracking = useTracking();
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    var title = props.title, subTitle = props.subTitle, photo = props.photo, phoneNumber = props.phoneNumber, email = props.email, contactsAmount = props.contactsAmount, itemNumber = props.itemNumber, isWide = props.isWide;
    var isOneElement = contactsAmount === 1;
    var styles = personCardStyles(isOneElement);
    var lastOddContact = contactsAmount % 2 !== 0 && itemNumber + 1 === contactsAmount;
    var isEvenContact = itemNumber % 2 === 0;
    var cardPhoto = ((_a = photo.value) === null || _a === void 0 ? void 0 : _a.urlSmall) && ((_b = photo.value) === null || _b === void 0 ? void 0 : _b.urlMedium) && ((_c = photo.value) === null || _c === void 0 ? void 0 : _c.urlLarge)
        ? photo
        : startPageData === null || startPageData === void 0 ? void 0 : startPageData.contactPersonImageFallback;
    function openModal() {
        tracking.event("Contacts", "Open generic contact", "".concat((title === null || title === void 0 ? void 0 : title.value) || ""));
        props.openModal(itemNumber);
    }
    return (React.createElement(Col, { sm: isWide ? 12 : contactsAmount === 1 || lastOddContact ? 8 : 5, md: isWide || contactsAmount === 1 ? 6 : 4, smStart: isWide
            ? null
            : contactsAmount === 1 || lastOddContact
                ? 2
                : isEvenContact
                    ? 1
                    : 6, mdStart: (!isWide && itemNumber === 0 && contactsAmount < 3
            ? Math.max((12 - 3 * contactsAmount) / 3, 0)
            : null), className: styles.contactCol },
        React.createElement(AnimatedRevealBlock, { revealDelay: itemNumber * 100, className: styles.animationWrapper },
            React.createElement("article", { className: styles.contact, onClick: function () { return openModal(); } },
                React.createElement("div", { className: styles.bioSection },
                    React.createElement("h4", { className: styles.name }, title && React.createElement(RenderProperty, { value: title })),
                    React.createElement(RenderProperty, { value: cardPhoto, className: styles.photo })),
                React.createElement("div", { className: styles.infoSection },
                    ShowPropertyOnEdit(subTitle) && (React.createElement(RenderProperty, { value: subTitle, className: styles.defaultText })),
                    React.createElement("div", { className: styles.ctaButtons },
                        React.createElement(Button, { type: "link", onClick: function () { return props.openModal(itemNumber); } },
                            React.createElement("span", { className: styles.accessibilityText }, "Open contact details"),
                            React.createElement(LocalizedLabel, { section: "Global", label: "ShowMore" })),
                        React.createElement("div", { className: styles.iconWrapper },
                            React.createElement("button", null,
                                React.createElement("span", { className: styles.accessibilityText }, "Open contact details"),
                                React.createElement(FAIcon, { icon: "envelope", className: styles.icon })),
                            phoneNumber.value && (React.createElement("button", null,
                                React.createElement("span", { className: styles.accessibilityText }, "Open contact details"),
                                React.createElement(FAIcon, { icon: "phone", className: styles.icon })))),
                        React.createElement("div", { className: styles.iconWrapperMobile },
                            React.createElement("a", { href: "mailto:".concat(email.value) },
                                React.createElement("span", { className: styles.accessibilityText },
                                    "Write an email to ",
                                    email.value),
                                React.createElement(FAIcon, { icon: "envelope", className: styles.icon })),
                            phoneNumber.value && (React.createElement("a", { href: "tel: ".concat(formattedPhoneNumber(phoneNumber.value)) },
                                React.createElement("span", { className: styles.accessibilityText },
                                    "Call to a phone number ",
                                    phoneNumber.value),
                                React.createElement(FAIcon, { icon: "phone", className: styles.icon }))))))))));
};
export function formattedPhoneNumber(phoneNumber) {
    return phoneNumber.replace(/\s/g, "");
}
