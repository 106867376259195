import cn from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Col } from "@basics/layout";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { useTracking } from "@hooks/useTracking";
import { RenderProperty, ShowPropertyOnEdit } from "@/src/views/RenderProperty";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { Button, isExternal } from "@components/Button/Button";
import { CloseButton } from "@components/CloseButton";
import { FAIcon } from "@components/FAIcon/FAIcon";
import { useLocationCardStyles } from "./LocationCardStyles";
import { formattedPhoneNumber } from "./PersonCard";
export var LocationCard = function (_a) {
    var _b, _c, _d, _e;
    var locationName = _a.locationName, photo = _a.photo, role = _a.role, address = _a.address, email = _a.email, phoneNumber = _a.phoneNumber, faxNumber = _a.faxNumber, website = _a.website, itemNumber = _a.itemNumber, inContactList = _a.inContactList, description = _a.description, openModal = _a.openModal, onClose = _a.onClose;
    var _f = useContext(GlobalContentStoreContext), getStartPageData = _f.getStartPageData, getImmediateStartPageData = _f.getImmediateStartPageData;
    var _g = useState(getImmediateStartPageData()), startPageData = _g[0], setStartPageData = _g[1];
    var tracking = useTracking();
    var styles = useLocationCardStyles(inContactList);
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    var cardPhoto = ((_b = photo.value) === null || _b === void 0 ? void 0 : _b.urlSmall) && ((_c = photo.value) === null || _c === void 0 ? void 0 : _c.urlMedium) && ((_d = photo.value) === null || _d === void 0 ? void 0 : _d.urlLarge)
        ? photo
        : startPageData === null || startPageData === void 0 ? void 0 : startPageData.contactLocationImageFallback;
    var websiteLink = (_e = website === null || website === void 0 ? void 0 : website.value) === null || _e === void 0 ? void 0 : _e[0];
    function openModalFromCard(e, itemNumber) {
        if (e.target.tagName !== "A" &&
            e.target.tagName !== "BUTTON" &&
            e.target.tagName !== "SPAN")
            return openModal(itemNumber);
    }
    function handleOpenModal(e) {
        tracking.event("Contacts", "Open location contact".concat(websiteLink ? " link" : ""), (locationName === null || locationName === void 0 ? void 0 : locationName.value) || "Missing location contact name");
        openModalFromCard(e, itemNumber);
    }
    return (React.createElement(Col, { sm: inContactList ? 8 : 12, md: inContactList ? 6 : 9, smStart: inContactList ? 2 : null, mdStart: inContactList ? 3 : 2, className: styles.contactCol },
        React.createElement(AnimatedRevealBlock, { revealDelay: itemNumber * 100 },
            React.createElement("article", { className: styles.contact, onClick: function (e) { return handleOpenModal(e); } },
                onClose && React.createElement(CloseButton, { onClick: function () { return onClose(); } }),
                React.createElement("div", { className: styles.bioSection },
                    React.createElement("div", { className: styles.nameWrapper },
                        React.createElement("h4", { className: cn(styles.name, (role === null || role === void 0 ? void 0 : role.value) === "" && styles.nameAdditionalMargin) },
                            React.createElement(RenderProperty, { value: locationName })),
                        ShowPropertyOnEdit(role) && (React.createElement(RenderProperty, { value: role, className: styles.role }))),
                    React.createElement(RenderProperty, { value: cardPhoto, className: styles.photo })),
                React.createElement("div", { className: styles.infoWrapper },
                    description && (React.createElement("div", { className: styles.description },
                        React.createElement(RenderProperty, { value: description }))),
                    React.createElement("div", { className: styles.infoSection },
                        React.createElement("div", { className: styles.address },
                            React.createElement(RenderProperty, { value: address })),
                        React.createElement("div", { className: styles.contactNumbers },
                            React.createElement("a", { href: "mailto:".concat(email.value) },
                                React.createElement("span", { className: styles.accessibilityText },
                                    "Write an email to ",
                                    email.value),
                                React.createElement(RenderProperty, { value: email })),
                            phoneNumber.value && (React.createElement("a", { href: "tel: ".concat(formattedPhoneNumber(phoneNumber.value)) },
                                React.createElement("span", { className: styles.accessibilityText },
                                    "Call to a phone number ",
                                    phoneNumber.value),
                                React.createElement(RenderProperty, { value: phoneNumber }))),
                            ShowPropertyOnEdit(faxNumber) && (React.createElement("a", { href: "fax: ".concat(faxNumber.value) },
                                React.createElement("span", { className: styles.accessibilityText },
                                    "Fax to a number ",
                                    faxNumber.value),
                                React.createElement(RenderProperty, { value: faxNumber })))),
                        React.createElement("div", { className: styles.ctaButtons },
                            websiteLink ? (React.createElement(Button, { href: websiteLink.href, type: isExternal(websiteLink.href) ? "secondary" : "primary", target: isExternal(websiteLink.href) ? "_blank" : "_self" },
                                React.createElement("span", { className: styles.accessibilityText },
                                    "Open a website ",
                                    websiteLink.href),
                                isExternal(websiteLink.href) ? (React.createElement(LocalizedLabel, { section: "Contact", label: "VisitWebsite" })) : (React.createElement(LocalizedLabel, { section: "Contact", label: "ShowDetails" })))) : (React.createElement(Button, { type: "link", onClick: function () { return openModal(itemNumber); } },
                                React.createElement("span", { className: styles.accessibilityText }, "Open contact details"),
                                React.createElement(LocalizedLabel, { section: "Global", label: "ShowMore" }))),
                            React.createElement("div", { className: styles.iconWrapperMobile },
                                React.createElement("a", { href: "mailto:".concat(email.value) },
                                    React.createElement("span", { className: styles.accessibilityText },
                                        "Write an email to ",
                                        email.value),
                                    React.createElement(FAIcon, { icon: "envelope", className: styles.icon })),
                                phoneNumber.value && (React.createElement("a", { href: "tel: ".concat(formattedPhoneNumber(phoneNumber.value)) },
                                    React.createElement("span", { className: styles.accessibilityText },
                                        "Call to a phone number ",
                                        phoneNumber.value),
                                    React.createElement(FAIcon, { icon: "phone", className: styles.icon })))))))))));
};
