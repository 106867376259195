import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
var easeOutQuad = function (t) { return t * (2 - t); };
var countDecimalPlaces = function (value) { var _a; return ((_a = value.split(/[\.,]/g)[1]) === null || _a === void 0 ? void 0 : _a.length) || 0; };
function getLocaleDecimalSeparator(currentLanguage) {
    var separator = (1.1).toLocaleString(currentLanguage).substr(1, 1);
    return separator === "0" ? "" : separator;
}
function getLocaleThousandsSeparator(currentLanguage) {
    var separator = (1000).toLocaleString(currentLanguage).substr(1, 1);
    return separator === "0" ? "" : separator;
}
function formatNumber(value, decimalPlaces, currentLanguage, decimalSeparator, thousandsSeparator) {
    var _a;
    decimalSeparator =
        typeof decimalSeparator === "string"
            ? decimalSeparator
            : getLocaleDecimalSeparator(currentLanguage);
    thousandsSeparator =
        typeof thousandsSeparator === "string"
            ? thousandsSeparator
            : getLocaleThousandsSeparator(currentLanguage);
    var parts = value.toFixed(decimalPlaces).toString().split(/[\.,]/g);
    return (((_a = parts === null || parts === void 0 ? void 0 : parts[0]) === null || _a === void 0 ? void 0 : _a.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)) +
        (parts[1] ? decimalSeparator + parts[1] : ""));
}
export function CountUpAnimation(props) {
    var _a = useInView({
        threshold: 0.3,
        triggerOnce: true,
        delay: 100,
        rootMargin: "50px",
    }), ref = _a.ref, inView = _a.inView;
    var frameDuration = 1000 / 60;
    var duration = props.duration || 1000;
    var countTo = parseFloat(String(props.children));
    var _b = useState(0), count = _b[0], setCount = _b[1];
    useEffect(function () {
        if (inView) {
            var frame_1 = 0;
            var totalFrames_1 = Math.round(duration / frameDuration);
            var counter_1 = setInterval(function () {
                frame_1++;
                var progress = easeOutQuad(frame_1 / totalFrames_1);
                setCount(countTo * progress);
                if (frame_1 === totalFrames_1) {
                    clearInterval(counter_1);
                }
            }, frameDuration);
        }
    }, [countTo, duration, frameDuration, inView]);
    return (React.createElement("span", { ref: ref }, inView
        ? formatNumber(count, countDecimalPlaces(String(props.children)), props.currentLanguage, props.decimalSeparator, props.thousandsSeparator)
        : props.children));
}
