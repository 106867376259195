var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useState, useEffect } from "react";
import { Header } from "@components/Header/Header";
import { Footer } from "@components/Shared/Footer";
import { getUniqueId, GlobalContentStoreContext, } from "@hooks/GlobalContentStore";
import { createUseStyles } from "react-jss";
import { Col, Container, CONTAINER_OFFSET_WIDTHS, onBefore, onBreakpoint, rem, Row, } from "@basics/layout";
import { RenderProperty } from "@/src/views/RenderProperty";
import { typography } from "@basics/typography";
import { spacings } from "@basics/spacings";
import { Accordion, AccordionItem } from "@components/Accordion/Accordion";
import { RenderComponent } from "@/src/views/RenderComponent";
import { DownloadCenterContext } from "@hooks/DownloadCenterContext";
import { isDeviceResOrLower, useDeviceType } from "@hooks/useDeviceType";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import cn from "classnames";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    downloadCenter: __assign(__assign({ paddingTop: rem(spacings.m), paddingBottom: rem(spacings.m) }, onBreakpoint("sm", {
        paddingTop: rem(spacings.l),
        paddingBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        paddingTop: rem(spacings.xl),
        paddingBottom: rem(spacings.xxl),
    })),
    downloadCenterNoPaddingTop: __assign(__assign({ paddingTop: 0 }, onBreakpoint("sm", {
        paddingTop: 0,
    })), onBreakpoint("md", {
        paddingTop: 0,
    })),
    additionalTopContent: __assign(__assign({ marginTop: rem(spacings.m) }, onBreakpoint("sm", {
        marginTop: rem(spacings.l),
    })), onBreakpoint("md", {
        marginTop: rem(spacings.xl),
    })),
    headline: typography.h1,
    downloadItemsWrapper: __assign({}, onBreakpoint("sm", {
        marginLeft: rem(spacings.m),
    })),
    accordion: onBefore("sm", {
        paddingTop: spacings.xs,
        paddingBottom: spacings.xs,
    }),
    leftMenuCol: onBefore("sm", {
        zIndex: 1,
        marginBottom: rem(spacings.sam),
    }),
    leftMenuBackground: onBefore("sm", {
        zIndex: -1,
        position: "absolute",
        height: "100%",
        width: "100vw",
        backgroundColor: brandColors.ultraLightGrey,
        marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
    }),
});
export function DownloadCenterPage(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var queryParams = new URLSearchParams(window.location.search);
    var history = window.history;
    var _l = useContext(GlobalContentStoreContext), getStartPageData = _l.getStartPageData, getImmediateStartPageData = _l.getImmediateStartPageData;
    var _m = useState(getImmediateStartPageData()), startPageData = _m[0], setStartPageData = _m[1];
    var categories = props.data.downloads
        .expandedValue;
    var _o = useState({}), downloads = _o[0], setDownloads = _o[1];
    var _p = useState(queryParams.has("subcategory")
        ? Number(queryParams.get("subcategory"))
        : ((_d = (_c = (_b = (_a = categories === null || categories === void 0 ? void 0 : categories[0]) === null || _a === void 0 ? void 0 : _a.subcategories) === null || _b === void 0 ? void 0 : _b.expandedValue) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.contentLink.id) ||
            null), activeSubcategory = _p[0], setActiveSubcategory = _p[1];
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    useEffect(function () {
        var queryParams = new URLSearchParams();
        queryParams.set("subcategory", String(activeSubcategory));
        history.replaceState({}, "", "".concat(window.location.pathname, "?").concat(queryParams));
    }, [activeSubcategory, history]);
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    var downloadItems = __spreadArray(__spreadArray([], (((_e = downloads.downloadsLocal) === null || _e === void 0 ? void 0 : _e.expandedValue) || []), true), (((_f = downloads.downloadsGlobal) === null || _f === void 0 ? void 0 : _f.expandedValue) || []), true);
    var styles = useStyles();
    if (!startPageData)
        return null;
    var isUserRetention = ((_g = props.data.contactCta) === null || _g === void 0 ? void 0 : _g.value) === "enabled";
    var contactExpertData = ((_h = props.data.contactsUseDefaultContent) === null || _h === void 0 ? void 0 : _h.value)
        ? startPageData
        : props.data;
    var isAdditionalTopContent = !!((_j = props.data.additionalTopContent) === null || _j === void 0 ? void 0 : _j.expandedValue);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, __assign({}, startPageData)),
        React.createElement("main", { "data-testid": "DownloadCenterPage" },
            isAdditionalTopContent && (React.createElement(RenderProperty, { value: props.data.additionalTopContent, className: styles.additionalTopContent })),
            React.createElement(Container, { className: cn(styles.downloadCenter, isAdditionalTopContent && styles.downloadCenterNoPaddingTop) },
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 7, md: 8 },
                        React.createElement("h1", { className: styles.headline },
                            React.createElement(RenderProperty, { value: props.data.headline, fallbackValue: props.data.name })))),
                React.createElement(Row, null,
                    React.createElement(DownloadCenterContext.Provider, { value: {
                            downloads: downloads,
                            setDownloads: setDownloads,
                            activeSubcategory: activeSubcategory,
                            setActiveSubcategory: setActiveSubcategory,
                        } },
                        React.createElement(Col, { xs: 4, sm: 4, md: 3, className: styles.leftMenuCol },
                            React.createElement("div", { className: styles.leftMenuBackground }),
                            React.createElement(Accordion, { noOuterBorder: isMobile, className: styles.accordion }, (_k = props.data.downloads
                                .expandedValue) === null || _k === void 0 ? void 0 : _k.map(function (item, idx) {
                                var _a, _b;
                                return (React.createElement(AccordionItem, { key: idx, headline: React.createElement(RenderProperty, { value: item.categoryLabel }), initialIsActive: ((_b = (_a = item.subcategories.expandedValue) === null || _a === void 0 ? void 0 : _a.find(function (sc) { return sc.contentLink.id === activeSubcategory; })) === null || _b === void 0 ? void 0 : _b.contentLink.id) === activeSubcategory },
                                    React.createElement(RenderComponent, { key: idx, contentLink: item.contentLink })));
                            }))),
                        React.createElement(Col, { xs: 4, sm: 8, md: 9 },
                            React.createElement("div", { className: styles.downloadItemsWrapper }, downloadItems.map(function (item) { return (React.createElement(RenderComponent, { key: getUniqueId(item), contentLink: item.contentLink, context: { disableFeaturedOption: true } })); })))))),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            isUserRetention && React.createElement(ContactExpert, __assign({}, contactExpertData))),
        React.createElement(Footer, __assign({}, startPageData))));
}
