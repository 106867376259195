var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from "react";
export function usePrivacyStore(initialConsentData) {
    var _a = useState(0), retryCount = _a[0], setRetryCount = _a[1];
    var _b = useState(false), isInitialized = _b[0], setIsInitialized = _b[1];
    var _c = useState(null), consents = _c[0], setConsents = _c[1];
    var _d = useState(new Map()), consentsStatuses = _d[0], setConsentsStatuses = _d[1];
    var ppms = window["ppms"];
    var ppmsInitedCorrectly = window.__PIWIK_LOADED__;
    useEffect(function () {
        if (typeof ppmsInitedCorrectly === "undefined" && retryCount < 30) {
            var timerId_1 = window.setTimeout(function () { return setRetryCount(function (c) { return c + 1; }); }, 500);
            return function () { return window.clearTimeout(timerId_1); };
        }
        if (!initialConsentData || !ppms || !ppmsInitedCorrectly) {
            setIsInitialized(true);
            return;
        }
        var settingsPromise = new Promise(function (resolve, reject) {
            return ppms.cm.api("getComplianceSettings", function (response) {
                resolve(response);
            }, function (error) {
                reject(error);
            });
        });
        var typesSettings = new Promise(function (resolve, reject) {
            return ppms.cm.api("getComplianceTypes", function (response) {
                resolve(response);
            }, function (error) {
                reject(error);
            });
        });
        var promisesCancelled = false;
        Promise.all([settingsPromise, typesSettings])
            .then(function (_a) {
            var _b;
            var settings = _a[0], types = _a[1];
            var piwikConsents = new Set(types);
            var epiConsents = (_b = initialConsentData.consentOptions.value) === null || _b === void 0 ? void 0 : _b.map(function (consentOption) { return consentOption.consentId; });
            var commonConsents = new Set(epiConsents === null || epiConsents === void 0 ? void 0 : epiConsents.filter(function (key) { return key && piwikConsents.has(key); }));
            if (promisesCancelled)
                return;
            setConsents(new Map((initialConsentData.consentOptions.value || [])
                .filter(function (consentOption) {
                return commonConsents.has(consentOption.consentId || "");
            })
                .map(function (consentOption) { return [
                consentOption.consentId || "",
                consentOption,
            ]; })));
            setConsentsStatuses(new Map(Object.entries(settings.consents)
                .filter(function (_a) {
                var key = _a[0], _ = _a[1];
                return commonConsents.has(key);
            })
                .filter(function (_a) {
                var _ = _a[0], value = _a[1];
                return value.status !== -1;
            })
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                return [key, value.status === 1];
            })));
        })
            .catch(function (reason) { return console.error(reason); })
            .then(function () { return !promisesCancelled && setIsInitialized(true); });
        return function () {
            promisesCancelled = true;
        };
    }, [initialConsentData, ppms, ppmsInitedCorrectly, retryCount]);
    var getConsentStatus = function (consentName) {
        return !!consentsStatuses.get(consentName);
    };
    var getAllConsents = function () {
        return ((consents &&
            Array.from(consents.entries()).map(function (_a) {
                var key = _a[0], value = _a[1];
                return (__assign(__assign({}, value), { status: !!consentsStatuses.get(key) }));
            })) ||
            []);
    };
    var showPrivacyNote = function () {
        return ((consents &&
            Array.from(consents.keys()).some(function (key) { return consentsStatuses.get(key) === undefined; })) ||
            false);
    };
    var setUserConsentStatuses = function (consents) {
        setConsentsStatuses(function (s) {
            return Array.from(consents.entries()).reduce(function (acc, _a) {
                var key = _a[0], value = _a[1];
                return acc.set(key, value);
            }, new Map(s));
        });
        ppms.cm.api("setComplianceSettings", {
            consents: Array.from(consents.entries()).reduce(function (acc, _a) {
                var _b;
                var key = _a[0], value = _a[1];
                return (__assign(__assign({}, acc), (_b = {}, _b[key] = { status: value ? 1 : 0 }, _b)));
            }, {}),
        }, function () {
            return;
        }, function (error) {
            console.error(error);
        });
    };
    var allowRequired = function () {
        consents &&
            setUserConsentStatuses(new Map(Array.from(consents.entries()).map(function (_a) {
                var key = _a[0], value = _a[1];
                return [
                    key,
                    value.isRequired || false,
                ];
            })));
    };
    var allowSelected = setUserConsentStatuses;
    var allowAll = function () {
        consents &&
            setUserConsentStatuses(new Map(Array.from(consents.keys()).map(function (key) { return [key, true]; })));
    };
    return {
        getConsentStatus: getConsentStatus,
        getAllConsents: getAllConsents,
        showPrivacyNote: showPrivacyNote,
        allowRequired: allowRequired,
        allowSelected: allowSelected,
        allowAll: allowAll,
        isInitialized: isInitialized,
    };
}
export var PrivacyContext = React.createContext(null);
