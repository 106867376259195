var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, Row, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { PrivacyContext } from "@hooks/PrivacyStore";
import { RenderProperty } from "@/src/views/RenderProperty";
import { Button } from "@components/Button/Button";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { brandColors } from "@basics/brand-colors";
import { ShowDetailsSection } from "./ShowDetailsSection";
import { ConsentOptionsSection } from "./ConsentOptionsSection";
var useStyles = createUseStyles({
    modal: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(15,18,20,.84)",
        display: "flex",
        alignItems: "center",
        zIndex: 100,
    },
    modalBox: {
        display: "flex",
        flexDirection: "column",
        maxHeight: "80vh",
        overflow: "auto",
    },
    modalMain: __assign({ backgroundColor: brandColors.white, padding: rem(spacings.sam) }, onBreakpoint("sm", {
        padding: rem(spacings.m),
    })),
    headline: typography.h4,
    text: customTypography(typography.textSmall, { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 }),
    textSection: __assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
    })),
    checkboxSection: __assign(__assign({ display: "grid" }, onBreakpoint("sm", {
        gridTemplateColumns: "repeat(2, 1fr)",
    })), { gridTemplateColumns: "1fr" }),
    ctaSection: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: rem(spacings.sam),
    },
    ctaSectionDiv: __assign({ padding: 0, border: "none", font: "inherit", color: "inherit", textAlign: "inherit", background: "none", display: "none" }, onBreakpoint("sm", {
        display: "flex",
        alignItems: "center",
    })),
    textSectionDetails: __assign({ padding: 0, border: "none", font: "inherit", color: "inherit", textAlign: "inherit", background: "none", display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%" }, onBreakpoint("sm", {
        display: "none",
    })),
    ctaSectionSpan: __assign({}, customTypography(__assign(__assign({}, typography.textSmall), { marginBottom: 0, marginRight: rem(spacings.s), cursor: "pointer" }), {}, {
        marginBottom: 0,
    })),
    modalButtons: __assign({ display: "flex", gap: rem(spacings.s), justifyContent: "flex-end", flexWrap: "wrap", padding: rem(spacings.sam), backgroundColor: brandColors.ultraLightGrey }, onBreakpoint("sm", {
        padding: rem(spacings.m),
        gap: rem(spacings.sam),
    })),
    modalButtonsButton: __assign({ flexBasis: "100%", justifyContent: "center" }, onBreakpoint("sm", {
        flexBasis: "auto",
    })),
    icon: FAIconStyles({
        width: 24,
        height: 24,
    }),
});
export function PrivacyOverlay(props) {
    var _a, _b;
    var isVisible = props.isVisible, consentDescription = props.consentDescription, close = props.close, consentOptions = props.consentOptions, consentTitle = props.consentTitle, privacyPage = props.privacyPage, overlayButtons = props.overlayButtons;
    var styles = useStyles();
    var privacyContext = useContext(PrivacyContext);
    var _c = useState(new Map()), consentStateMap = _c[0], setConsentStateMap = _c[1];
    var _d = useState(false), showDetails = _d[0], setShowDetails = _d[1];
    var privacyPageLink = (_a = privacyPage.value) === null || _a === void 0 ? void 0 : _a[0];
    useEffect(function () {
        setConsentStateMap(new Map((privacyContext === null || privacyContext === void 0 ? void 0 : privacyContext.getAllConsents().map(function (consent) { return [consent.consentId || "", consent.status]; })) || []));
    }, [privacyContext]);
    if (!privacyContext || !privacyContext.isInitialized)
        return null;
    var allowRequired = privacyContext.allowRequired, allowSelected = privacyContext.allowSelected, allowAll = privacyContext.allowAll;
    var actionMap = new Map([
        ["accept_necessary", allowRequired],
        ["save_selection", function () { return allowSelected(consentStateMap); }],
        ["accept_all", allowAll],
    ]);
    return ((isVisible && (React.createElement("div", { className: styles.modal, "data-testid": "PrivacyOverlay" },
        React.createElement(Container, null,
            React.createElement(Row, null,
                React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2 },
                    React.createElement("div", { className: styles.modalBox },
                        React.createElement("div", { className: styles.modalMain },
                            React.createElement("h2", { className: styles.headline },
                                React.createElement(RenderProperty, { value: consentTitle })),
                            React.createElement("div", { className: styles.textSection },
                                React.createElement(RenderProperty, { className: styles.text, value: consentDescription }),
                                React.createElement("button", { onClick: function () { return setShowDetails(!showDetails); }, className: styles.textSectionDetails, "data-testid": "PrivacyOverlayShowDetails" },
                                    React.createElement("span", { className: styles.ctaSectionSpan },
                                        React.createElement(LocalizedLabel, { section: "Contact", label: "ShowDetails" })),
                                    React.createElement(FAIcon, { icon: showDetails ? "chevron-up" : "chevron-down", className: styles.icon }))),
                            React.createElement(ConsentOptionsSection, { consentOptions: consentOptions, consentStateMap: consentStateMap, setConsentStateMap: setConsentStateMap }),
                            React.createElement("div", { className: styles.ctaSection },
                                React.createElement(Button, { type: "link", href: privacyPageLink === null || privacyPageLink === void 0 ? void 0 : privacyPageLink.href, target: privacyPageLink === null || privacyPageLink === void 0 ? void 0 : privacyPageLink.target, title: privacyPageLink === null || privacyPageLink === void 0 ? void 0 : privacyPageLink.title }, privacyPageLink === null || privacyPageLink === void 0 ? void 0 : privacyPageLink.text),
                                React.createElement("button", { onClick: function () { return setShowDetails(!showDetails); }, className: styles.ctaSectionDiv, "data-testid": "PrivacyOverlayShowDetails" },
                                    React.createElement("span", { className: styles.ctaSectionSpan },
                                        React.createElement(LocalizedLabel, { section: "Contact", label: "ShowDetails" })),
                                    React.createElement(FAIcon, { icon: showDetails ? "chevron-up" : "chevron-down", className: styles.icon })))),
                        showDetails && (React.createElement(ShowDetailsSection, { consentOptions: consentOptions })),
                        React.createElement("div", { className: styles.modalButtons }, (_b = overlayButtons.value) === null || _b === void 0 ? void 0 : _b.map(function (button, idx) { return (React.createElement(Button, { key: idx, type: button.buttonType || "secondary", onClick: function () {
                                var _a;
                                close();
                                (_a = actionMap.get(button.function)) === null || _a === void 0 ? void 0 : _a();
                            }, className: styles.modalButtonsButton }, button.label)); }))))))))) ||
        null);
}
