var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "@basics/layout";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizationContext, LocalizedLabel, localizedLabelString, } from "@hooks/LocalizationContext";
import { RenderProperty } from "@/src/views/RenderProperty";
import { Button, ButtonWrapper } from "@components/Button/Button";
import { DownloadItem } from "@components/DownloadItem/DownloadItem";
import { DynamicDownloadItem } from "@components/DownloadItem/DynamicDownloadItem";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { downloadStyles } from "./DownloadBlock";
export function getDynamicDownloadData(pageLanguage, category) {
    return fetch("/api/hdiglobal/page-with-downloads/results?pagelanguage=".concat(pageLanguage, "&category=").concat(category)).then(function (r) { return r.json(); });
}
export function DynamicDownloadBlock(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = useContext(GlobalContentStoreContext), getStartPageData = _l.getStartPageData, getImmediateStartPageData = _l.getImmediateStartPageData, getWebsiteData = _l.getWebsiteData;
    var websiteData = getWebsiteData();
    var _m = useState(getImmediateStartPageData()), startPageData = _m[0], setStartPageData = _m[1];
    var localizationCtx = useContext(LocalizationContext);
    var _o = useState(null), dynamicDownloadData = _o[0], setDownloadCenterDynamicData = _o[1];
    var localeSelection = websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage.link;
    var _p = useState(false), isLoading = _p[0], setLoading = _p[1];
    var _q = useState(30), itemsNumber = _q[0], setItemsNumber = _q[1];
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    var category = (_b = (_a = props.downloadCategoriesFilter) === null || _a === void 0 ? void 0 : _a.expandedValue) === null || _b === void 0 ? void 0 : _b.map(function (category) { return category.contentLink.id.toString(); }).join(",");
    var loadItems = useCallback(function () {
        setLoading(true);
        return getDynamicDownloadData(localeSelection ? localeSelection : "", category ? [category] : []).then(function (response) {
            setLoading(false);
            return response;
        });
    }, [category, localeSelection]);
    useEffect(function () {
        setDownloadCenterDynamicData(null);
        loadItems().then(function (response) { return response && setDownloadCenterDynamicData(response); });
    }, [loadItems]);
    var styles = downloadStyles({ isFullWidth: props.isFullWidth });
    if (!startPageData)
        return null;
    var downloadCenterUrl = (_d = (_c = startPageData === null || startPageData === void 0 ? void 0 : startPageData.downloadCenter) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.url;
    if ((dynamicDownloadData === null || dynamicDownloadData === void 0 ? void 0 : dynamicDownloadData.TotalCount) === 0 && !((_e = props.downloadsManual) === null || _e === void 0 ? void 0 : _e.value))
        return null;
    if (isLoading) {
        return null;
    }
    return (React.createElement(Container, { className: cn(styles.downloadsBackground, !props.isFullWidth && styles.downloads), isFluid: true },
        React.createElement(Container, null,
            React.createElement(Row, null,
                !props.isFullWidth && (React.createElement(Col, { md: 4 },
                    React.createElement(Row, null,
                        React.createElement(Col, { sm: 8, smStart: 2, md: 12, mdStart: null, className: styles.infoSection },
                            React.createElement(SemanticHeader, { className: styles.headline, headerSize: (_f = props.headerSize) === null || _f === void 0 ? void 0 : _f.value },
                                React.createElement(RenderProperty, { value: props.headline, fallbackValue: localizedLabelString({
                                        section: "Download",
                                        label: "Headline",
                                        localizationCtx: localizationCtx,
                                    }) })),
                            React.createElement("span", { className: styles.description },
                                React.createElement(RenderProperty, { value: props.description, fallbackValue: localizedLabelString({
                                        section: "Download",
                                        label: "Description",
                                        localizationCtx: localizationCtx,
                                    }) })),
                            props.buttons ? (React.createElement(React.Fragment, null, (_g = props.buttons.value) === null || _g === void 0 ? void 0 : _g.map(function (b, idx) { return (React.createElement(ButtonWrapper, __assign({}, b, { key: idx }))); }))) : (downloadCenterUrl && (React.createElement(Button, { type: "secondary", href: downloadCenterUrl },
                                React.createElement(LocalizedLabel, { section: "Download", label: "CenterButton" })))))))),
                React.createElement(Col, { md: props.isFullWidth ? 12 : 8 }, dynamicDownloadData && (React.createElement(React.Fragment, null, (_h = dynamicDownloadData === null || dynamicDownloadData === void 0 ? void 0 : dynamicDownloadData.Hits) === null || _h === void 0 ? void 0 :
                    _h.slice(0, itemsNumber).map(function (item, idx) { return React.createElement(DynamicDownloadItem, __assign({}, item, { key: idx })); }), (_k = (_j = props.downloadsManual) === null || _j === void 0 ? void 0 : _j.expandedValue) === null || _k === void 0 ? void 0 :
                    _k.map(function (item, idx) { return (React.createElement(DownloadItem, __assign({}, item, { key: idx }))); }),
                    React.createElement(React.Fragment, null, dynamicDownloadData &&
                        dynamicDownloadData.TotalCount > itemsNumber && (React.createElement(Col, { className: styles.moreButton },
                        React.createElement(Button, { type: "secondary", onClick: function () { return setItemsNumber(itemsNumber + 30); } },
                            React.createElement(LocalizedLabel, { section: "Search", label: "LoadMore" }))))))))))));
}
