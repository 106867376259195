var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import cn from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, rem, Row } from "@basics/layout";
import { getCurrentLocale } from "@basics/locales";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizationContext, LocalizedLabel, localizedLabelString, } from "@hooks/LocalizationContext";
import { isDeviceResOrHigher, isDeviceResOrLower, useDeviceType, } from "@hooks/useDeviceType";
import { AnimatedRenderProperty, RenderProperty, } from "@/src/views/RenderProperty";
import { Accordion, AccordionItem } from "@components/Accordion/Accordion";
import { AdaptiveImageComponent } from "@components/AdaptiveImageComponent/AdaptiveImageComponent";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { DynamicDownloadBlock } from "@components/Block/DynamicDownloadBlock";
import { Button, isExternal, isFile } from "@components/Button/Button";
import { CloudinaryVideoPlayer } from "@components/CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import { Header } from "@components/Header/Header";
import { RadioButton } from "@components/RadioButton/RadioButton";
import { Footer } from "@components/Shared/Footer";
import { Spinner } from "@components/Spinner/Spinner";
import { TagBadge } from "@components/TagBadge/TagBadge";
import { namePropertyValue } from "@models/utils";
import { brandColors } from "@basics/brand-colors";
var PAGE_SIZE = 30;
export function getNewsWallData(page, contentLink, language, filters, tags, excludeEvents, sortByFeaturedEvents, company, author) {
    var queryParameters = new URLSearchParams();
    queryParameters.set("rootContentLink", (contentLink === null || contentLink === void 0 ? void 0 : contentLink.id.toString()) || "");
    queryParameters.set("language", language || "");
    queryParameters.set("page", page.toString());
    queryParameters.set("size", PAGE_SIZE.toString());
    queryParameters.set("category", filters.map(function (i) { return i.toString(); }).join(","));
    queryParameters.set("tag", tags.join(","));
    company && queryParameters.set("company", company.join(","));
    excludeEvents &&
        queryParameters.set("excludeEvents", excludeEvents.toString());
    sortByFeaturedEvents &&
        queryParameters.set("sortByFeaturedEvents", sortByFeaturedEvents.toString());
    author && queryParameters.set("author", author || "");
    return fetch("/api/hdiglobal/news-wall/items?".concat(queryParameters)).then(function (r) {
        return r.json();
    });
}
var useNewsWallStyles = createUseStyles({
    newsWall: __assign(__assign({ paddingTop: rem(spacings.m), paddingBottom: rem(spacings.l) }, onBreakpoint("sm", {
        paddingTop: rem(spacings.l),
        paddingBottom: rem(spacings.xl),
    })), onBreakpoint("md", {
        paddingTop: rem(spacings.xl),
        paddingBottom: rem(spacings.xxl),
    })),
    newsWallNoPaddingTop: __assign(__assign({ paddingTop: 0 }, onBreakpoint("sm", {
        paddingTop: 0,
    })), onBreakpoint("md", {
        paddingTop: 0,
    })),
    additionalTopContent: __assign(__assign({ marginTop: rem(spacings.m) }, onBreakpoint("sm", {
        marginTop: rem(spacings.l),
    })), onBreakpoint("md", {
        marginTop: rem(spacings.xl),
    })),
    headline: typography.h1,
    mobileOnly: __assign({ display: "block" }, onBreakpoint("sm", {
        display: "none",
    })),
    tabletOnly: __assign(__assign({ display: "none" }, onBreakpoint("sm", {
        display: "block",
    })), onBreakpoint("md", {
        display: "none",
    })),
    desktopOnly: __assign({ display: "none" }, onBreakpoint("md", {
        display: "block",
    })),
    cardsContainer: {
        marginBottom: "-".concat(spacings.sam),
    },
    eventsHeadline: __assign({}, customTypography(__assign({}, typography.h4), {}, {
        marginBottom: rem(spacings.sam),
    })),
    moreButton: {
        display: "flex",
        justifyContent: "center",
        paddingTop: rem(spacings.m),
    },
    noResults: __assign(__assign({}, typography.textDefault), { textAlign: "center" }),
});
export function NewsWallPage(props) {
    var _a, _b, _c, _d, _e, _f;
    var DESKTOP_NUMBER_OF_COLUMNS = 3;
    var TABLET_NUMBER_OF_COLUMNS = 3;
    var initialQueryParametrs = new URLSearchParams(window.location.search);
    var dataFilters = useMemo(function () { return (props.data.filters.expandedValue || []); }, [props.data.filters.expandedValue]);
    var _g = useContext(GlobalContentStoreContext), getStartPageData = _g.getStartPageData, getImmediateStartPageData = _g.getImmediateStartPageData, getWebsiteData = _g.getWebsiteData;
    var styles = useNewsWallStyles();
    var websiteData = getWebsiteData();
    var localizationCtx = useContext(LocalizationContext);
    var _h = useState(getImmediateStartPageData()), startPageData = _h[0], setStartPageData = _h[1];
    var _j = useState(null), newsWallData = _j[0], setNewsWallData = _j[1];
    var _k = useState(false), isLoading = _k[0], setLoading = _k[1];
    var COMPANY_OPTIONS = [
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiGlobal",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-global",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiSpecialty",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-specialty",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiInsurance",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-insurance",
        },
    ];
    var _l = useState(getCurrentLocale(websiteData, initialQueryParametrs)), localeSelection = _l[0], setLocaleSelection = _l[1];
    var _m = useState(initialQueryParametrs.has("category")
        ? Number(initialQueryParametrs.get("category") || "")
        : null), activeFilter = _m[0], setActiveFilter = _m[1];
    var _o = useState(new Set(initialQueryParametrs.has("tag")
        ? decodeURI(initialQueryParametrs.get("tag") || "")
            .split(",")
            .filter(Boolean)
        : [])), activeTags = _o[0], setActiveTags = _o[1];
    var _p = useState(initialQueryParametrs.has("company")
        ? decodeURI(initialQueryParametrs.get("company") || "")
        : null), company = _p[0], setCompany = _p[1];
    var _q = useState(initialQueryParametrs.has("author")
        ? decodeURI(initialQueryParametrs.get("author") || "")
        : null), author = _q[0], setAuthor = _q[1];
    var _r = useState(0), page = _r[0], setPage = _r[1];
    var loadItems = useCallback(function (requestedPage) {
        setLoading(true);
        var apiTags = Array.from(activeTags.values()).map(function (key) { return key; });
        return getNewsWallData(requestedPage, props.data.contentLink, localeSelection, activeFilter ? [activeFilter] : dataFilters.map(function (f) { return f.contentLink.id; }), apiTags, false, false, company ? [company] : [], author ? author : "").then(function (response) {
            setPage(requestedPage);
            setLoading(false);
            return response;
        });
    }, [
        activeFilter,
        activeTags,
        author,
        company,
        dataFilters,
        localeSelection,
        props.data.contentLink,
    ]);
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    useEffect(function () {
        var queryParameters = new URLSearchParams();
        var qsTags = Array.from(activeTags.values()).map(function (key) { return key; });
        activeFilter && queryParameters.set("category", String(activeFilter));
        qsTags.length > 0 &&
            queryParameters.set("tag", encodeURI(qsTags.join(",")));
        company && queryParameters.set("company", company);
        localeSelection && queryParameters.set("locale", localeSelection);
        author && queryParameters.set("author", author);
        history.replaceState({}, "", "".concat(window.location.pathname, "?").concat(queryParameters));
        loadItems(1).then(function (response) { return response && setNewsWallData(response); });
    }, [activeFilter, activeTags, author, company, loadItems, localeSelection]);
    if (!startPageData)
        return null;
    var dataTags = (newsWallData &&
        new Map((_a = newsWallData.Filter.Tags) === null || _a === void 0 ? void 0 : _a.map(function (item) { return [item.ContentLink, item]; }))) ||
        new Map();
    var cards = (newsWallData && newsWallData.Hits) || [];
    var contactExpertData = ((_b = props.data.contactsUseDefaultContent) === null || _b === void 0 ? void 0 : _b.value)
        ? startPageData
        : props.data;
    var isAdditionalTopContent = !!((_c = props.data.additionalTopContent) === null || _c === void 0 ? void 0 : _c.expandedValue);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, __assign({}, startPageData)),
        React.createElement("main", { "data-testid": "NewsWallPage" },
            isAdditionalTopContent && (React.createElement(RenderProperty, { value: props.data.additionalTopContent, className: styles.additionalTopContent })),
            React.createElement(Container, { className: cn(styles.newsWall, isAdditionalTopContent && styles.newsWallNoPaddingTop) },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 4, sm: 4, md: 3 },
                        React.createElement("aside", null,
                            React.createElement("h1", { className: styles.headline },
                                React.createElement(AnimatedRenderProperty, { value: props.data.metaTitle, fallbackValue: props.data.name })),
                            React.createElement(FiltersElement, { headline: localizedLabelString({
                                    section: "NewsWallPage",
                                    label: "Filters",
                                    localizationCtx: localizationCtx,
                                }), defaultFilter: localizedLabelString({
                                    section: "NewsWallPage",
                                    label: "AllItems",
                                    localizationCtx: localizationCtx,
                                }), filters: dataFilters, activeFilter: activeFilter, setActiveFilter: setActiveFilter, activeTags: Array.from(activeTags.values()).map(function (tagId) {
                                    return dataTags.get(tagId);
                                }), removeActiveTag: function (tagId) {
                                    return setActiveTags(function (s) {
                                        var newState = new Set(s);
                                        newState.delete(tagId);
                                        return newState;
                                    });
                                }, companies: COMPANY_OPTIONS, activeCompany: company, setActiveCompany: setCompany, localeSelection: localeSelection, setLocaleSelection: setLocaleSelection, localeOptions: props.data.existingLanguages || [], teaserContent: props.data.teaserContent, enableLocalFilter: (_d = props.data.enableLocalFilter) === null || _d === void 0 ? void 0 : _d.value, enableCompanyFilter: (_e = props.data.enableCompanyFilter) === null || _e === void 0 ? void 0 : _e.value, author: author || "", clearAuthor: setAuthor }, newsWallData && newsWallData.UpcomingEvents.length > 0 && (React.createElement("div", { className: styles.desktopOnly },
                                React.createElement("span", { className: styles.eventsHeadline },
                                    React.createElement(LocalizedLabel, { section: "NewsWallPage", label: "UpcomingEvents" })),
                                newsWallData.UpcomingEvents.map(function (item, idx) { return (React.createElement(Event, __assign({}, item, { key: idx }))); }))))),
                        newsWallData && (React.createElement("div", { className: cn(styles.tabletOnly, styles.cardsContainer) }, cards
                            .filter(function (_, cidx) { return cidx % TABLET_NUMBER_OF_COLUMNS === 0; })
                            .map(function (card, cidx) { return (React.createElement(Card, __assign({}, card, { key: cidx, startPageData: startPageData, onTagClick: function (tagId) {
                                return !activeTags.has(tagId) &&
                                    setActiveTags(function (s) { return new Set(s).add(tagId); });
                            } }))); })))),
                    newsWallData ? (cards.length > 0 ? (React.createElement(React.Fragment, null,
                        new Array(DESKTOP_NUMBER_OF_COLUMNS)
                            .fill(0)
                            .map(function (_, idx) { return (React.createElement(Col, { md: 3, className: cn(styles.desktopOnly, styles.cardsContainer), key: idx }, cards
                            .filter(function (_, cidx) {
                            return cidx % DESKTOP_NUMBER_OF_COLUMNS === idx;
                        })
                            .map(function (card, cidx) { return (React.createElement(Card, __assign({}, card, { key: cidx, startPageData: startPageData, onTagClick: function (tagId) {
                                return !activeTags.has(tagId) &&
                                    setActiveTags(function (s) { return new Set(s).add(tagId); });
                            } }))); }))); }),
                        new Array(TABLET_NUMBER_OF_COLUMNS - 1)
                            .fill(0)
                            .map(function (_, idx) { return (React.createElement(Col, { sm: 4, className: cn(styles.tabletOnly, styles.cardsContainer), key: idx }, cards
                            .filter(function (_, cidx) {
                            return cidx % TABLET_NUMBER_OF_COLUMNS === idx + 1;
                        })
                            .map(function (card, cidx) { return (React.createElement(Card, __assign({}, card, { key: cidx, startPageData: startPageData, onTagClick: function (tagId) {
                                return !activeTags.has(tagId) &&
                                    setActiveTags(function (s) { return new Set(s).add(tagId); });
                            } }))); }))); }),
                        React.createElement(Col, { xs: 4, className: cn(styles.mobileOnly, styles.cardsContainer) }, cards.map(function (card, idx) { return (React.createElement(Card, __assign({}, card, { key: idx, startPageData: startPageData, revealDelay: -1, onTagClick: function (tagId) {
                                return !activeTags.has(tagId) &&
                                    setActiveTags(function (s) { return new Set(s).add(tagId); });
                            } }))); })))) : (React.createElement(Col, { xs: 4, sm: 12, md: 9, className: styles.noResults },
                        React.createElement(LocalizedLabel, { section: "NewsWallPage", label: "NoResultsFound" })))) : (React.createElement(Col, { xs: 4, sm: 12, md: 9 },
                        React.createElement(Spinner, null)))),
                newsWallData && newsWallData.TotalCount > page * PAGE_SIZE && (React.createElement(Row, null,
                    React.createElement(Col, { className: styles.moreButton }, isLoading ? (React.createElement(Spinner, null)) : (React.createElement(Button, { type: "secondary", onClick: function () {
                            loadItems(page + 1).then(function (response) {
                                return response &&
                                    setNewsWallData(function (s) {
                                        return s
                                            ? __assign(__assign({}, s), { Hits: __spreadArray(__spreadArray([], s.Hits, true), response.Hits, true) }) : response;
                                    });
                            });
                        } },
                        React.createElement(LocalizedLabel, { section: "NewsWallPage", label: "MoreInsights" }))))))),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            React.createElement(DynamicDownloadBlock, { downloadCategoriesFilter: props.data.downloadCategoriesFilter, downloadsManual: props.data.downloadsManual }),
            ((_f = props.data.contactCta) === null || _f === void 0 ? void 0 : _f.value) === "enabled" && (React.createElement(ContactExpert, __assign({}, contactExpertData)))),
        React.createElement(Footer, __assign({}, startPageData))));
}
var useCardStyles = createUseStyles({
    newsItem: {
        position: "relative",
        backgroundColor: brandColors.ultraLightGrey,
        paddingBottom: rem(spacings.m),
        marginBottom: rem(spacings.sam),
    },
    newsItemSection: {
        paddingTop: rem(spacings.m),
        paddingLeft: rem(spacings.m),
        paddingRight: rem(spacings.m),
    },
    h4: __assign(__assign({}, typography.h4), { wordBreak: "break-word" }),
    tags: {
        display: "flex",
        flexWrap: "wrap",
    },
    category: __assign(__assign({}, typography.caption), { display: "flex", flexDirection: "column", marginBottom: rem(spacings.xs) }),
    eventImage: {
        marginBottom: rem(spacings.s),
    },
    description: customTypography(__assign({}, typography.textDefault), {}, {
        marginBottom: rem(spacings.s),
    }),
    button: {
        marginTop: rem(spacings.s),
    },
    spacing: {
        paddingTop: rem(spacings.sam),
        marginBottom: rem(spacings.m),
    },
    nonBreakingSpan: {
        whiteSpace: "nowrap",
        //fix for Firefox
        "@supports (-moz-appearance:none)": {
            whiteSpace: "normal",
        },
        //fix for Safari
        "@media not all and (min-resolution:.001dpcm)": {
            whiteSpace: "normal",
        },
    },
});
function Card(props) {
    var _a, _b, _c, _d, _e;
    var styles = useCardStyles();
    var _f = useState(false), forceVideoPlay = _f[0], setForceVideoPlay = _f[1];
    var revealDelay = props.revealDelay === undefined
        ? Math.floor(300 * Math.random())
        : props.revealDelay;
    var isAutoplay = props.Type === "Video" && props.StartAction === "autoplay";
    var isLoop = props.Type === "Video" && props.EndAction === "loop_video";
    var resetOnEnd = props.Type === "Video" && props.EndAction === "reset_to_thumbnail";
    var defaultPosterUrl = props.Type === "Video" &&
        (props.ImageUrlLarge || props.ImageUrlMedium || props.ImageUrlSmall);
    return (React.createElement(AnimatedRevealBlock, { className: styles.newsItem, "data-testid": "NewsWallCard", revealDelay: revealDelay },
        (props.Type === "Article" || props.Type === "CrossReference") &&
            (props.ImageUrlLarge ||
                props.ImageUrlMedium ||
                props.ImageUrlSmall) && (React.createElement("a", { href: (props.Type === "Article" ? props.Url : props.Link) || undefined },
            React.createElement(AdaptiveImageComponent, { large: props.ImageUrlLarge, medium: props.ImageUrlMedium, small: props.ImageUrlSmall, alt: props.ImageAlt }))),
        props.Type === "Video" && (React.createElement(CloudinaryVideoPlayer, { publicId: props.CloudinaryVideoPublicID || "", logoImageUrl: ((_a = props.startPageData.logo.value) === null || _a === void 0 ? void 0 : _a.url) || "", showControls: false, autoplay: isAutoplay, loop: isLoop, resetVideo: resetOnEnd, thumbnailUrl: defaultPosterUrl || undefined, withOverlay: true, forcePlay: forceVideoPlay, setForcePlay: setForceVideoPlay })),
        React.createElement("div", { className: styles.newsItemSection },
            (props.Type === "Article" || props.Type === "CrossReference") && (React.createElement("span", { className: styles.category }, (_c = (_b = props.Categories) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.Name)),
            props.Type === "Event" && (React.createElement("span", { className: styles.category }, props.DateTo ? (React.createElement(React.Fragment, null,
                React.createElement("span", null,
                    React.createElement("span", { className: styles.nonBreakingSpan }, props.DateFrom),
                    React.createElement("span", { className: styles.nonBreakingSpan },
                        " ",
                        "- ",
                        props.DateTo)),
                React.createElement("span", null, props.Location))) : (React.createElement("span", null,
                props.DateFrom,
                " | ",
                props.Location)))),
            React.createElement("h4", { className: styles.h4 }, props.Title),
            props.Type === "Event" && (React.createElement(React.Fragment, null, props.ImageUrlLarge ||
                props.ImageUrlMedium ||
                props.ImageUrlSmall ? (React.createElement(AdaptiveImageComponent, { large: props.ImageUrlLarge, medium: props.ImageUrlMedium, small: props.ImageUrlSmall, alt: props.ImageAlt, className: styles.eventImage })) : !!props.Description ? (React.createElement("div", { className: styles.description }, props.Description)) : null)),
            (((_d = props.Tags) === null || _d === void 0 ? void 0 : _d.length) || 0) > 0 && (React.createElement("div", { className: styles.tags }, (_e = props.Tags) === null || _e === void 0 ? void 0 : _e.map(function (tag, idx) { return (React.createElement(TagBadge, { isReadOnly: true, label: tag.Name, onBadgeClick: function () { return props.onTagClick(tag.ContentLink); }, key: idx })); }))),
            props.Type === "Article" && (React.createElement(Button, { type: "link", href: props.Url, className: styles.button },
                React.createElement(LocalizedLabel, { section: "Global", label: "ReadMore" }))),
            (props.Type === "CrossReference" || props.Type === "Event") &&
                props.Link && (React.createElement(Button, { type: isFile(props.Link) && !isExternal(props.Link)
                    ? "primary"
                    : props.Type === "Event"
                        ? "link"
                        : "secondary", href: props.Link, title: props.LinkText || "", target: "_blank", className: styles.button }, props.LinkText)),
            props.Type === "Video" && (React.createElement(Button, { type: "secondary", onClick: function () { return setForceVideoPlay(true); }, icon: "play", className: styles.button },
                React.createElement(LocalizedLabel, { section: "Global", label: "PlayVideo" }))))));
}
var useFiltersStyle = createUseStyles({
    element: __assign({ marginBottom: rem(spacings.xs) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.s),
    })),
    tags: __assign({ display: "flex", flexWrap: "wrap" }, onBreakpoint("sm", {
        marginTop: rem(spacings.s),
    })),
    accordeonElement: {
        marginBottom: 0,
        marginTop: 0,
    },
    mainElement: __assign({ marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.m),
    })),
    teaserContentItem: __assign(__assign({ marginTop: rem(spacings.sam) }, onBreakpoint("sm", {
        marginTop: rem(spacings.m),
    })), { "& > div": __assign({ marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
            marginBottom: rem(spacings.m),
        })) }),
});
export function FiltersElement(props) {
    var _a, _b;
    var localizationCtx = useContext(LocalizationContext);
    var numberOfActiveTags = ((_a = props.activeTags) === null || _a === void 0 ? void 0 : _a.length) || 0;
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var tabletAtLeast = isDeviceResOrHigher(deviceType, "tablet");
    var styles = useFiltersStyle();
    var activeTags = numberOfActiveTags > 0;
    return (React.createElement("div", { className: styles.element },
        React.createElement("div", { className: styles.mainElement },
            React.createElement(Accordion, { noOuterBorder: isMobile, className: styles.accordeonElement },
                React.createElement(AccordionItem, { headline: props.headline, initialIsActive: tabletAtLeast && !!props.activeFilter },
                    React.createElement("div", { "data-testid": "NewsWallPageFilters" },
                        React.createElement(RadioButton, { onCheck: function () { return props.setActiveFilter(null); }, checked: !props.activeFilter, label: props.defaultFilter }),
                        props.filters.map(function (item) { return (React.createElement(RadioButton, { key: item.contentLink.id, onCheck: function () { return props.setActiveFilter(item.contentLink.id); }, checked: props.activeFilter === item.contentLink.id, label: namePropertyValue(item.name) || "" })); }))),
                props.enableCompanyFilter && (React.createElement(AccordionItem, { headline: localizedLabelString({
                        section: "JobListing",
                        label: "Company",
                        localizationCtx: localizationCtx,
                    }), initialIsActive: tabletAtLeast && !!props.activeCompany },
                    React.createElement(RadioButton, { onCheck: function () { return props.setActiveCompany(null); }, checked: !props.activeCompany, label: props.defaultFilter }),
                    props.companies.map(function (option) { return (React.createElement(RadioButton, { key: option.value, onCheck: function () { return props.setActiveCompany(option.value); }, checked: props.activeCompany === option.value, label: namePropertyValue(option.label) || "" })); }))),
                props.enableLocalFilter && (React.createElement(AccordionItem, { headline: localizedLabelString({
                        section: "Search",
                        label: "Locales",
                        localizationCtx: localizationCtx,
                    }) }, props.localeOptions.map(function (option, idx) { return (React.createElement(RadioButton, { key: idx, label: option.displayName, onCheck: function () { return props.setLocaleSelection(option.name); }, checked: props.localeSelection === option.name })); })))),
            activeTags && (React.createElement("div", { className: styles.tags, "data-testid": "NewsWallPageFilterTags" }, (_b = props.activeTags) === null || _b === void 0 ? void 0 : _b.filter(function (i) { return i !== undefined; }).map(function (item) { return (React.createElement(TagBadge, { label: item.Name, onCloseClick: function () { var _a; return (_a = props.removeActiveTag) === null || _a === void 0 ? void 0 : _a.call(props, item.ContentLink); }, key: item.ContentLink })); }))),
            props.author && (React.createElement("div", { className: styles.tags },
                React.createElement(TagBadge, { label: props.author, onCloseClick: function () { return props.clearAuthor && props.clearAuthor(null); } })))),
        props.children,
        React.createElement(RenderProperty, { value: props.teaserContent, theme: "filters", className: styles.teaserContentItem })));
}
var useEventStyles = createUseStyles({
    event: {
        display: "flex",
        flexDirection: "column",
        marginBottom: rem(spacings.s),
        borderBottom: "3px solid ".concat(brandColors.ultraLightGrey),
    },
    date: __assign(__assign({}, typography.caption), { marginBottom: rem(spacings.xs), display: "flex", flexDirection: "column" }),
    headline: __assign({}, typography.h4),
    button: {
        marginBottom: rem(spacings.s),
        alignSelf: "flex-start",
    },
    nonBreakingSpan: {
        whiteSpace: "nowrap",
        //fix for Firefox
        "@supports (-moz-appearance:none)": {
            whiteSpace: "normal",
        },
        //fix for Safari
        "@media not all and (min-resolution:.001dpcm)": {
            whiteSpace: "normal",
        },
    },
});
export function Event(props) {
    var styles = useEventStyles();
    return (React.createElement("div", { className: cn(styles.event, props.className), "data-testid": "NewsWallPageEvents" },
        React.createElement("div", { className: styles.date }, props.DateTo ? (React.createElement(React.Fragment, null,
            React.createElement("span", null,
                React.createElement("span", { className: styles.nonBreakingSpan }, props.DateFrom),
                React.createElement("span", { className: styles.nonBreakingSpan },
                    " - ",
                    props.DateTo)),
            React.createElement("span", null, props.Location))) : (React.createElement("span", null,
            props.DateFrom,
            " | ",
            props.Location))),
        React.createElement("h4", { className: styles.headline }, props.Title),
        React.createElement(Button, { type: "link", href: props.Link || "", className: styles.button }, props.LinkText)));
}
