var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { DownloadCenterContext } from "@hooks/DownloadCenterContext";
import { typography } from "@basics/typography";
import { RenderProperty } from "@/src/views/RenderProperty";
import cn from "classnames";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    button: __assign(__assign({}, typography.textDefault), { width: "100%", background: "none", textAlign: "left", transition: "color 0.3s ease", color: brandColors.darkGrey, "&:hover": {
            color: brandColors.black,
            transition: "color 0.3s ease",
        } }),
    active: {
        fontWeight: "bold",
    },
});
export function DownloadCenterSubcategoryBlock(props) {
    var _a;
    var _b = useContext(DownloadCenterContext), activeSubcategory = _b.activeSubcategory, setActiveSubcategory = _b.setActiveSubcategory, setDownloads = _b.setDownloads;
    var _c = useState(false), isActive = _c[0], setActive = _c[1];
    var buttonRef = useRef(null);
    var styles = useStyles();
    useEffect(function () {
        if (activeSubcategory === props.data.contentLink.id) {
            setDownloads({
                downloadsLocal: props.data.downloadsLocal,
                downloadsGlobal: props.data.downloadsGlobal,
            });
            setActive(true);
        }
        else {
            setActive(false);
        }
    }, [
        activeSubcategory,
        props.data.contentLink.id,
        props.data.downloadsGlobal,
        props.data.downloadsLocal,
        setDownloads,
    ]);
    return (React.createElement("button", { onClick: function () {
            setActiveSubcategory(props.data.contentLink.id);
        }, className: cn(styles.button, (_a = {}, _a[styles.active] = isActive, _a)), ref: buttonRef, onMouseUp: function () { return buttonRef.current && buttonRef.current.blur(); } },
        React.createElement(RenderProperty, { value: props.data.subCategoryLabel })));
}
