var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { brandColors } from "@basics/brand-colors";
import { rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import React from "react";
import { createUseStyles } from "react-jss";
var useStyles = createUseStyles({
    detailsSection: {
        backgroundColor: brandColors.ultraLightGrey,
        padding: rem(spacings.m),
        paddingBottom: 0,
        "& > *:last-child": {
            marginBottom: "0 !important",
        },
    },
    detailsSectionH4: typography.h4,
    detailsSectionP: __assign({}, customTypography(__assign({}, typography.textSmall), { marginBottom: rem(spacings.s) }, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.m) })),
});
export var ShowDetailsSection = function (_a) {
    var _b;
    var consentOptions = _a.consentOptions;
    var styles = useStyles();
    return (React.createElement("div", { className: styles.detailsSection }, (_b = consentOptions.value) === null || _b === void 0 ? void 0 : _b.map(function (consent) { return (React.createElement(React.Fragment, { key: consent.consentId || "" },
        React.createElement("h4", { className: styles.detailsSectionH4 }, consent.optionTitle),
        React.createElement("p", { className: styles.detailsSectionP }, consent.optionDescription))); })));
};
