var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Col, Container, Row } from "@basics/layout";
import { isDeviceResOrLower, useDeviceType } from "@hooks/useDeviceType";
import { ButtonWrapper } from "@components/Button/Button";
import cn from "classnames";
import { RenderProperty } from "@/src/views/RenderProperty";
import { AdaptiveImageComponent } from "@components/AdaptiveImageComponent/AdaptiveImageComponent";
import { CloudinaryVideoPlayer } from "@components/CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import { useStyles } from "./styles";
export default function VisualHeaderCarousel(props) {
    var _a;
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var settings = {
        arrows: !isMobile,
        dots: isMobile,
        infinite: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };
    var imageRef = useRef(null);
    var _b = useState(0), imageHeight = _b[0], setImageHeight = _b[1];
    useEffect(function () {
        var _a;
        var currentImage = imageRef.current;
        setImageHeight(((_a = imageRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height) || 0);
        document.addEventListener("resize", function () {
            var _a;
            setImageHeight(((_a = imageRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height) || 0);
        });
        return function () {
            return document.removeEventListener("resize", function () {
                setImageHeight((currentImage === null || currentImage === void 0 ? void 0 : currentImage.getBoundingClientRect().height) || 0);
            });
        };
    }, []);
    var styles = useStyles({ menuCollapsed: props.menuCollapsed, imageHeight: imageHeight });
    return (React.createElement("div", { className: styles.visualHeaderCarousel },
        React.createElement(Slider, __assign({}, settings), (_a = props.items) === null || _a === void 0 ? void 0 : _a.map(function (item, idx) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
            return (React.createElement("div", { key: idx },
                React.createElement("div", { ref: imageRef }, item.contentType[1] === "HeaderCarouselElementBlock" ? (React.createElement(AdaptiveImageComponent, { fullHD: ((_a = item.imageAdaptive.value) === null || _a === void 0 ? void 0 : _a.urlOriginal) || "", large: ((_b = item.imageAdaptive.value) === null || _b === void 0 ? void 0 : _b.urlLarge) || "", medium: ((_c = item.imageAdaptive.value) === null || _c === void 0 ? void 0 : _c.urlLarge) || "", small: ((_d = item.imageAdaptive.value) === null || _d === void 0 ? void 0 : _d.urlLarge) || "", alt: ((_e = item.imageAdaptive.value) === null || _e === void 0 ? void 0 : _e.alternateText) || "", className: styles.carouselImage })) : item.contentType[1] === "HeaderCarouselVideoElementBlock" ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.carouselVideoWrapper },
                        React.createElement(CloudinaryVideoPlayer, { publicId: ((_f = item.cloudinaryVideoPublicID) === null || _f === void 0 ? void 0 : _f.value) || "", logoImageUrl: "", showControls: false, autoplay: true, loop: true, resetVideo: true, inHeaderCarousel: true, thumbnailUrl: ((_g = item.thumbnail.value) === null || _g === void 0 ? void 0 : _g.urlOriginal) || "" })),
                    React.createElement(AdaptiveImageComponent, { fullHD: ((_h = item.thumbnail.value) === null || _h === void 0 ? void 0 : _h.urlOriginal) || "", large: ((_j = item.thumbnail.value) === null || _j === void 0 ? void 0 : _j.urlLarge) || "", medium: ((_k = item.thumbnail.value) === null || _k === void 0 ? void 0 : _k.urlLarge) || "", small: ((_l = item.thumbnail.value) === null || _l === void 0 ? void 0 : _l.urlLarge) || "", alt: ((_m = item.thumbnail.value) === null || _m === void 0 ? void 0 : _m.alternateText) || "", className: styles.carouselStaticMobileImage }))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.carouselSVGWrapper },
                        React.createElement("object", { type: "image/svg+xml", data: ((_p = (_o = item.vectorImage) === null || _o === void 0 ? void 0 : _o.value) === null || _p === void 0 ? void 0 : _p.url) || undefined, className: styles.carouselSVG })),
                    React.createElement(AdaptiveImageComponent, { fullHD: ((_q = item.staticImage.value) === null || _q === void 0 ? void 0 : _q.urlOriginal) || "", large: ((_r = item.staticImage.value) === null || _r === void 0 ? void 0 : _r.urlLarge) || "", medium: ((_s = item.staticImage.value) === null || _s === void 0 ? void 0 : _s.urlLarge) || "", small: ((_t = item.staticImage.value) === null || _t === void 0 ? void 0 : _t.urlLarge) || "", alt: ((_u = item.staticImage.value) === null || _u === void 0 ? void 0 : _u.alternateText) || "", className: styles.carouselStaticMobileImage })))),
                React.createElement(Container, { className: styles.carouselCardContainer },
                    React.createElement(Row, null,
                        React.createElement(Col, { sm: 10, md: item.alignment.value === "center" ? 10 : 6, mdStart: item.alignment.value === "right"
                                ? 5
                                : item.alignment.value === "center"
                                    ? 1
                                    : undefined, className: item.alignment.value === "right"
                                ? styles.rightContentColumn
                                : undefined },
                            React.createElement("div", { className: cn(styles.carouselCard, item.alignment.value === "center" && "center") },
                                React.createElement("h3", { className: styles.carouselCardHeadline },
                                    React.createElement(RenderProperty, { value: item.headline })),
                                React.createElement("p", { className: styles.carouselCardDescription },
                                    React.createElement(RenderProperty, { value: item.description })), (_v = item.buttons.value) === null || _v === void 0 ? void 0 :
                                _v.map(function (b, idx) { return (React.createElement(ButtonWrapper, __assign({}, b, { key: idx }))); })))))));
        }))));
}
