var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { fonts } from "@basics/fonts";
import { CONTAINER_OFFSET_WIDTHS, Col, Container, MARGIN_OF_CONTAINER_LIMITED, MAX_LAYOUT_WIDTH, Row, getGridColumnWidth, onBefore, onBreakpoint, rem, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { RenderProperty, ShowPropertyOnEdit } from "@/src/views/RenderProperty";
import { BreadcrumbComponent } from "@components/BreadcrumbComponent/BreadcrumbComponent";
import { Button } from "@components/Button/Button";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import { Header } from "@components/Header/Header";
import { Footer } from "@components/Shared/Footer";
import { brandColors } from "@basics/brand-colors";
var useSectionLandingPageStyles = createUseStyles({
    firstContainer: {
        paddingTop: rem(spacings.m),
    },
    headline: typography.h1,
    description: __assign({}, customTypography(__assign({}, typography.textDefault), {}, {
        marginBottom: rem(spacings.xl),
    })),
});
export function SectionLandingPage(props) {
    var _a, _b, _c, _d;
    var _e = useContext(GlobalContentStoreContext), getStartPageData = _e.getStartPageData, getImmediateStartPageData = _e.getImmediateStartPageData;
    var styles = useSectionLandingPageStyles(props.data.listingStyle.value);
    var _f = useState(getImmediateStartPageData()), startPageData = _f[0], setStartPageData = _f[1];
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    if (!startPageData)
        return null;
    var contactExpertData = ((_a = props.data.contactsUseDefaultContent) === null || _a === void 0 ? void 0 : _a.value)
        ? startPageData
        : props.data;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, __assign({}, startPageData)),
        React.createElement("main", { "data-testid": "SectionLandingPage" },
            React.createElement(Container, { className: styles.firstContainer },
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement(BreadcrumbComponent, null)),
                    React.createElement(Col, { sm: 10, md: 7 }, ((_b = props.data.title) === null || _b === void 0 ? void 0 : _b.value) && (React.createElement("h1", { className: styles.headline },
                        React.createElement(RenderProperty, { value: props.data.title, fallbackValue: props.data.metaTitle })))),
                    React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2 }, ((_c = props.data.description) === null || _c === void 0 ? void 0 : _c.value) && (React.createElement(RenderProperty, { value: props.data.description, fallbackValue: props.data.metaDescription, className: styles.description }))))),
            React.createElement(Container, { isFluid: props.data.listingStyle.value === "single-column" },
                React.createElement(Row, null, (props.data.pages.expandedValue ||
                    []).map(function (page, idx) {
                    return props.data.listingStyle.value === "single-column" ? (React.createElement(SinglePageCard, __assign({ index: idx }, page, { key: idx }))) : (React.createElement(PageCard, __assign({ big: props.data.listingStyle.value === "two-columns" }, page, { key: idx })));
                }))),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            ((_d = props.data.contactCta) === null || _d === void 0 ? void 0 : _d.value) === "enabled" && (React.createElement(ContactExpert, __assign({}, contactExpertData)))),
        React.createElement(Footer, __assign({}, startPageData))));
}
var usePageCardStyles = createUseStyles({
    cardItemColumn: __assign(__assign({ marginBottom: rem(spacings.sam) }, onBreakpoint("md", {
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    cardItem: {
        backgroundColor: brandColors.ultraLightGrey,
        height: "100%",
    },
    itemSection: __assign(__assign({ padding: "".concat(spacings.sam, " ").concat(spacings.sam, " ").concat(spacings.m) }, onBreakpoint("sm", {
        padding: function () { return "".concat(spacings.m, " ").concat(spacings.sam); },
    })), onBreakpoint("md", {
        padding: function (isBig) { return (isBig ? rem(spacings.xl) : rem(spacings.m)); },
    })),
    h4: __assign(__assign({}, typography.h4), onBefore("sm", {
        marginBottom: rem(spacings.s),
    })),
    textDefault: __assign(__assign(__assign(__assign(__assign({}, typography.textDefault), fonts.book), onBefore("sm", {
        display: "none",
    })), onBreakpoint("sm", {
        marginBottom: rem(spacings.xl),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xl.add(spacings.xs)),
    })),
    button: __assign(__assign({}, onBreakpoint("sm", {
        marginTop: 0,
        position: "absolute",
        bottom: function () { return rem(spacings.m); },
    })), onBreakpoint("md", {
        bottom: function (isBig) { return (isBig ? rem(spacings.xl) : rem(spacings.l)); },
    })),
    icon: {
        marginLeft: rem(spacings.s),
    },
});
function PageCard(props) {
    var styles = usePageCardStyles(props.big);
    return (React.createElement(Col, { xs: 4, sm: 6, md: props.big ? 6 : 4, className: styles.cardItemColumn },
        React.createElement("div", { "data-testid": "SectionLandingPageCard-".concat(props.big ? "big" : "small"), className: styles.cardItem },
            ShowPropertyOnEdit(props.metaImageAdaptive) && (React.createElement("a", { href: props.url || undefined },
                React.createElement(RenderProperty, { value: props.metaImageAdaptive }))),
            React.createElement("div", { className: styles.itemSection },
                props.metaTitle.value && (React.createElement("h4", { className: styles.h4 }, props.metaTitle.value)),
                React.createElement("p", { className: styles.textDefault }, props.metaDescription.value),
                React.createElement(Button, { type: "link", className: styles.button, href: props.url || undefined },
                    React.createElement(LocalizedLabel, { section: "Global", label: "ReadMore" }))))));
}
var useSinglePageCardStyles = createUseStyles({
    cardColumn: __assign(__assign({ display: "flex", justifyContent: "center", marginBottom: rem(spacings.l) }, onBreakpoint("sm", {
        marginBottom: "calc(".concat(getGridColumnWidth("sm"), " + ").concat(spacings.xxl, ")"),
    })), onBreakpoint("md", {
        marginBottom: "calc(".concat(getGridColumnWidth("md"), " + ").concat(spacings.xxl, ")"),
    })),
    centerItem: {
        position: "relative",
        width: "calc(min(100vw, ".concat(MAX_LAYOUT_WIDTH, "px))"),
    },
    textContainer: __assign(__assign({ marginTop: "calc(100vmin / 16 * 10 - ".concat(spacings.l, ")") }, onBreakpoint("sm", {
        marginTop: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
        top: "calc(".concat(getGridColumnWidth("sm"), ")"),
    })), onBreakpoint("md", {
        top: "calc(".concat(getGridColumnWidth("md"), ")"),
    })),
    itemSectionColumn: {
        gridRow: 1,
        gridColumnStart: function (isEven) { return (isEven ? 1 : undefined); },
    },
    leftItem: __assign(__assign({}, onBreakpoint("sm", {
        marginLeft: -CONTAINER_OFFSET_WIDTHS["big"],
    })), onBreakpoint("md", {
        // INFO(mkarol): CSS Calc magic here :) We are moving to the left by value of margin:auto of container minus single padding
        marginLeft: "calc(-1 * ".concat(MARGIN_OF_CONTAINER_LIMITED, " - ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px)"),
    })),
    itemSection: __assign(__assign({ display: "flex", flexDirection: "column", backgroundColor: brandColors.ultraLightGrey, padding: "".concat(spacings.l, " ").concat(spacings.sam) }, onBreakpoint("sm", {
        backgroundColor: "transparent",
        padding: function (isEven) {
            return isEven
                ? "".concat(spacings.l, " calc(").concat(getGridColumnWidth("sm"), ") ").concat(spacings.l, " ").concat(spacings.m)
                : "".concat(spacings.l, " ").concat(spacings.m, " ").concat(spacings.l, " calc(").concat(getGridColumnWidth("sm"), ")");
        },
    })), onBreakpoint("md", {
        padding: function (isEven) {
            return isEven
                ? "".concat(spacings.xxl, " calc(").concat(getGridColumnWidth("md"), ") ").concat(spacings.xxl, " 0")
                : "".concat(spacings.xxl, " 0 ").concat(spacings.xxl, " calc(").concat(getGridColumnWidth("md"), ")");
        },
    })),
    emptyBackgroundColumn: __assign(__assign({ gridRow: 1, display: "none" }, onBreakpoint("sm", {
        display: "block",
    })), { gridColumnStart: function (isEven) { return (isEven ? 1 : undefined); } }),
    emptyBackground: {
        backgroundColor: brandColors.ultraLightGrey,
        height: "100%",
        // INFO(mkarol): CSS Calc magic here :) We are adding the size of the margin:auto of container to the width plus single padding
        width: "calc(".concat(MARGIN_OF_CONTAINER_LIMITED, " + ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px + 100%)"),
        position: "absolute",
    },
    h4: __assign(__assign({}, typography.h4), { wordBreak: "break-word" }),
    textDefault: customTypography(__assign(__assign(__assign({}, typography.textDefault), fonts.book), { position: "absolute", height: "100%", overflow: "hidden" }), { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 }),
    teaserImage: __assign(__assign({}, onBefore("sm", {
        position: "absolute",
        width: "100vw",
        marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
    })), onBreakpoint("sm", {
        width: "calc(".concat(MARGIN_OF_CONTAINER_LIMITED, " + ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px + 100%)"),
    })),
    button: {
        alignSelf: "flex-start",
    },
    icon: {
        marginLeft: rem(spacings.s),
    },
    fullHeight: {
        height: "100%",
    },
    descriptionBox: __assign(__assign(__assign({ display: "none", position: "relative", flex: 1 }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.m),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xl),
    })), { "&:after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: rem(spacings.m.times(2)),
            background: "linear-gradient(to bottom, ".concat(brandColors.ultraLightGrey, "00, ").concat(brandColors.ultraLightGrey, "FF 100%)"),
        } }),
});
function SinglePageCard(props) {
    var isEven = props.index % 2 === 0;
    var styles = useSinglePageCardStyles(isEven);
    return (React.createElement(Col, { md: 12, className: styles.cardColumn, dataTestid: "SinglePageCard" },
        React.createElement("div", { className: styles.centerItem },
            React.createElement(Container, null,
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 9, smStart: isEven ? 3 : undefined },
                        React.createElement(RenderProperty, { value: props.metaImageAdaptive, className: cn(styles.teaserImage, !isEven && styles.leftItem) })))),
            React.createElement("div", { className: styles.textContainer },
                React.createElement(Container, { className: styles.fullHeight },
                    React.createElement(Row, { className: styles.fullHeight },
                        React.createElement(Col, { xs: 4, sm: 4, smStart: isEven ? undefined : 8, className: cn(styles.emptyBackgroundColumn, styles.fullHeight) },
                            React.createElement("div", { className: cn(styles.emptyBackground, isEven && styles.leftItem) })),
                        React.createElement(Col, { xs: 4, sm: 4, smStart: isEven ? undefined : 8, className: cn(styles.itemSectionColumn, styles.fullHeight) },
                            React.createElement("div", { className: cn(styles.itemSection, styles.fullHeight) },
                                React.createElement("h4", { className: styles.h4 }, props.metaTitle.value),
                                React.createElement("div", { className: styles.descriptionBox },
                                    React.createElement("p", { className: styles.textDefault }, props.metaDescription.value)),
                                React.createElement(Button, { className: styles.button, type: "secondary", href: props.url || undefined },
                                    React.createElement(LocalizedLabel, { section: "Global", label: "ReadMore" }))))))))));
}
