var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React, { useCallback, useContext, useEffect, useRef, useState, } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, CONTAINER_OFFSET_WIDTHS, onBefore, onBreakpoint, rem, Row, } from "@basics/layout";
import { getCurrentLocale } from "@basics/locales";
import { spacings } from "@basics/spacings";
import { typography } from "@basics/typography";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizationContext, LocalizedLabel, localizedLabelString, } from "@hooks/LocalizationContext";
import { isDeviceResOrHigher, isDeviceResOrLower, useDeviceType, } from "@hooks/useDeviceType";
import { RenderProperty } from "@/src/views/RenderProperty";
import { Accordion, AccordionItem } from "@components/Accordion/Accordion";
import { Button } from "@components/Button/Button";
import { Checkbox } from "@components/Checkbox/Checkbox";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import { DynamicDownloadItem, } from "@components/DownloadItem/DynamicDownloadItem";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { Header } from "@components/Header/Header";
import { Input } from "@components/Input/Input";
import { RadioButton } from "@components/RadioButton/RadioButton";
import { Footer } from "@components/Shared/Footer";
import { Spinner } from "@components/Spinner/Spinner";
import { switchSetOption } from "./JobListingPage";
import { namePropertyValue } from "@models/utils";
import { brandColors } from "@basics/brand-colors";
export function getDownloadCenterDynamicData(contentLink, pageLanguage, query, category, market, language, company, typeOfFile) {
    var queryParameters = new URLSearchParams();
    query && queryParameters.set("q", query);
    queryParameters.set("rootContentLink", (contentLink === null || contentLink === void 0 ? void 0 : contentLink.id.toString()) || "");
    queryParameters.set("pagelanguage", pageLanguage || "");
    queryParameters.set("category", Array.from(category).filter(Boolean).join(","));
    queryParameters.set("market", Array.from(market).filter(Boolean).join(","));
    language && queryParameters.set("language", language.join(","));
    company && queryParameters.set("company", company.join(","));
    queryParameters.set("filetype", Array.from(typeOfFile).filter(Boolean).join(","));
    return fetch("/api/hdiglobal/download-center/results?".concat(queryParameters)).then(function (r) { return r.json(); });
}
var useStyles = createUseStyles({
    downloadCenter: __assign(__assign({ paddingTop: rem(spacings.m), paddingBottom: rem(spacings.m) }, onBreakpoint("sm", {
        paddingTop: rem(spacings.l),
        paddingBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        paddingTop: rem(spacings.xl),
        paddingBottom: rem(spacings.xxl),
    })),
    downloadCenterNoPaddingTop: __assign(__assign({ paddingTop: 0 }, onBreakpoint("sm", {
        paddingTop: 0,
    })), onBreakpoint("md", {
        paddingTop: 0,
    })),
    additionalTopContent: __assign(__assign({ marginTop: rem(spacings.m) }, onBreakpoint("sm", {
        marginTop: rem(spacings.l),
    })), onBreakpoint("md", {
        marginTop: rem(spacings.xl),
    })),
    headline: typography.h1,
    leftMenuCol: __assign({ gridRowEnd: "span 3" }, onBefore("sm", {
        zIndex: 1,
        marginBottom: rem(spacings.sam),
    })),
    leftMenuBackground: onBefore("sm", {
        zIndex: -1,
        position: "absolute",
        height: "100%",
        width: "100vw",
        backgroundColor: brandColors.ultraLightGrey,
        marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
    }),
    queryBar: __assign(__assign({}, onBefore("sm", { marginTop: rem(spacings.m) })), { display: "flex" }),
    resultsText: __assign(__assign({}, typography.textLarge), { marginTop: rem(spacings.m), paddingBottom: rem(spacings.sam), borderBottom: "2px solid ".concat(brandColors.lightGrey) }),
    moreButton: {
        display: "flex",
        justifyContent: "center",
        paddingTop: rem(spacings.m),
    },
});
export function DownloadCenterDynamicPage(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var initialQueryParameters = new URLSearchParams(window.location.search);
    var history = window.history;
    var _l = useContext(GlobalContentStoreContext), getStartPageData = _l.getStartPageData, getImmediateStartPageData = _l.getImmediateStartPageData, getWebsiteData = _l.getWebsiteData;
    var websiteData = getWebsiteData();
    var localizationCtx = useContext(LocalizationContext);
    var _m = useState(getImmediateStartPageData()), startPageData = _m[0], setStartPageData = _m[1];
    var formattedMarkets = (_b = (_a = props === null || props === void 0 ? void 0 : props.data.downloadMarketFilter) === null || _a === void 0 ? void 0 : _a.expandedValue) === null || _b === void 0 ? void 0 : _b.map(function (markets) {
        var _a, _b;
        return (__assign(__assign({}, markets), { relatedWebsiteLanguage: (_b = (_a = markets.relatedWebsiteLanguage) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.split(",") }));
    });
    var preselectedMarket = (_c = formattedMarkets === null || formattedMarkets === void 0 ? void 0 : formattedMarkets.filter(function (market) {
        var _a;
        return (_a = market.relatedWebsiteLanguage) === null || _a === void 0 ? void 0 : _a.find(function (locale) { var _a; return locale === ((_a = websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage) === null || _a === void 0 ? void 0 : _a.link) || ""; });
    })[0]) === null || _c === void 0 ? void 0 : _c.contentLink.id.toString();
    var formattedLanguages = (_e = (_d = props === null || props === void 0 ? void 0 : props.data.downloadLanguagesFilter) === null || _d === void 0 ? void 0 : _d.expandedValue) === null || _e === void 0 ? void 0 : _e.map(function (languages) {
        var _a, _b;
        return (__assign(__assign({}, languages), { relatedWebsiteLanguage: (_b = (_a = languages.relatedWebsiteLanguage) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.split(",") }));
    });
    var preselectedLanguage = (_f = formattedLanguages === null || formattedLanguages === void 0 ? void 0 : formattedLanguages.filter(function (language) {
        var _a;
        return (_a = language.relatedWebsiteLanguage) === null || _a === void 0 ? void 0 : _a.find(function (locale) { var _a; return locale === ((_a = websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage) === null || _a === void 0 ? void 0 : _a.link) || ""; });
    })[0]) === null || _f === void 0 ? void 0 : _f.contentLink.id.toString();
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var _o = useState(null), downloadCenterDynamicData = _o[0], setDownloadCenterDynamicData = _o[1];
    var localeSelection = getCurrentLocale(websiteData, initialQueryParameters);
    var _p = useState(false), isLoading = _p[0], setLoading = _p[1];
    var _q = useState(30), itemsNumber = _q[0], setItemsNumber = _q[1];
    var _r = useState(new Set(initialQueryParameters.has("category")
        ? decodeURI(initialQueryParameters.get("category") || "").split(",")
        : [])), category = _r[0], setCategory = _r[1];
    var _s = useState(new Set(initialQueryParameters.has("market")
        ? decodeURI(initialQueryParameters.get("market") || "").split(",")
        : preselectedMarket === null || preselectedMarket === void 0 ? void 0 : preselectedMarket.split(","))), market = _s[0], setMarket = _s[1];
    var _t = useState(initialQueryParameters.has("language")
        ? decodeURI(initialQueryParameters.get("language") || "")
        : preselectedLanguage), language = _t[0], setLanguage = _t[1];
    var _u = useState(initialQueryParameters.has("company")
        ? decodeURI(initialQueryParameters.get("company") || "")
        : null), company = _u[0], setCompany = _u[1];
    var _v = useState(new Set(initialQueryParameters.has("filetype")
        ? decodeURI(initialQueryParameters.get("filetype") || "").split(",")
        : [])), fileType = _v[0], setFileType = _v[1];
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    var styles = useStyles();
    var _w = useState(decodeURI(initialQueryParameters.get("query") || "")), query = _w[0], setQuery = _w[1];
    var queryInputRef = useRef(null);
    var isUserRetention = ((_g = props.data.contactCta) === null || _g === void 0 ? void 0 : _g.value) === "enabled";
    var contactExpertData = ((_h = props.data.contactsUseDefaultContent) === null || _h === void 0 ? void 0 : _h.value)
        ? startPageData
        : props.data;
    var isAdditionalTopContent = !!((_j = props.data.additionalTopContent) === null || _j === void 0 ? void 0 : _j.expandedValue);
    var loadItems = useCallback(function () {
        setLoading(true);
        return getDownloadCenterDynamicData(props.data.contentLink, localeSelection, query, category, market, language ? [language] : [], company ? [company] : [], fileType).then(function (response) {
            setLoading(false);
            return response;
        });
    }, [
        category,
        company,
        fileType,
        language,
        localeSelection,
        market,
        props.data.contentLink,
        query,
    ]);
    function searchFunction() {
        var _a;
        setQuery(((_a = queryInputRef.current) === null || _a === void 0 ? void 0 : _a.value) || "");
    }
    function clearQuery() {
        setQuery("");
    }
    useEffect(function () {
        var queryParameters = new URLSearchParams();
        localeSelection && queryParameters.set("locale", localeSelection);
        category.size &&
            queryParameters.set("category", encodeURI(Array.from(category.values()).join(",")));
        market.size &&
            queryParameters.set("market", encodeURI(Array.from(market.values()).join(",")));
        language && queryParameters.set("language", language);
        company && queryParameters.set("company", company);
        fileType.size &&
            queryParameters.set("filetype", encodeURI(Array.from(fileType.values()).join(",")));
        queryParameters.set("query", encodeURI(query));
        history.replaceState({}, "", "".concat(window.location.pathname, "?").concat(queryParameters));
        setDownloadCenterDynamicData(null);
        loadItems().then(function (response) { return response && setDownloadCenterDynamicData(response); });
    }, [
        category,
        company,
        fileType,
        history,
        language,
        loadItems,
        localeSelection,
        market,
        query,
    ]);
    if (!startPageData)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, __assign({}, startPageData)),
        React.createElement("main", { "data-testid": "DownloadCenterPage" },
            isAdditionalTopContent && (React.createElement(RenderProperty, { value: props.data.additionalTopContent, className: styles.additionalTopContent })),
            React.createElement(Container, { className: cn(styles.downloadCenter, isAdditionalTopContent && styles.downloadCenterNoPaddingTop) },
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 7, md: 8 },
                        React.createElement("h1", { className: styles.headline },
                            React.createElement(RenderProperty, { value: props.data.headline, fallbackValue: props.data.name })))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 4, sm: 4, md: 3, className: styles.leftMenuCol },
                        React.createElement("div", { className: styles.leftMenuBackground }),
                        React.createElement(FiltersElement, { categoryFilters: category, setCategoryFilters: function (changeFunc) {
                                return setCategory(function (state) {
                                    return changeFunc(state);
                                });
                            }, categories: props.data.downloadCategoriesFilterExpanded.value, marketFilters: market, setMarketFilters: function (changeFunc) {
                                return setMarket(function (state) {
                                    return changeFunc(state);
                                });
                            }, markets: props.data.downloadMarketFilter.expandedValue, activeLanguage: language, setActiveLanguage: setLanguage, languages: props.data.downloadLanguagesFilter.expandedValue, allLanguagesLabel: localizedLabelString({
                                section: "DownloadCenterPage",
                                label: "AllLanguages",
                                localizationCtx: localizationCtx,
                            }), activeCompany: company, setActiveCompany: setCompany, companies: props.data.downloadCompanyFilter.expandedValue, allCompaniesLabel: localizedLabelString({
                                section: "DownloadCenterPage",
                                label: "AllCompanies",
                                localizationCtx: localizationCtx,
                            }), typeOfFileFilters: fileType, setTypeOfFileFilters: function (changeFunc) {
                                return setFileType(function (state) {
                                    return changeFunc(state);
                                });
                            }, typesOfFiles: props.data.downloadTypeCategoriesFilter
                                .expandedValue })),
                    React.createElement(Col, { xs: 4, sm: 8, smStart: 4, md: 6, mdStart: 3 },
                        React.createElement("div", { className: styles.queryBar },
                            React.createElement(Input, { label: localizedLabelString({
                                    section: "Search",
                                    label: "Search",
                                    localizationCtx: localizationCtx,
                                }), initialValue: query, inputRef: queryInputRef, onSubmit: searchFunction, clearQuery: clearQuery, theme: isMobile ? "white" : "gray" }),
                            React.createElement(Button, { type: "primary", onClick: searchFunction },
                                React.createElement(LocalizedLabel, { section: "Search", label: "Search" })))),
                    React.createElement(Col, { xs: 4, sm: 8, smStart: 4, md: 9, mdStart: 3 },
                        isLoading && React.createElement(Spinner, null),
                        downloadCenterDynamicData && (React.createElement(React.Fragment, null,
                            React.createElement("p", { className: styles.resultsText },
                                React.createElement("strong", null, (downloadCenterDynamicData === null || downloadCenterDynamicData === void 0 ? void 0 : downloadCenterDynamicData.TotalCount) > 999
                                    ? "".concat(downloadCenterDynamicData === null || downloadCenterDynamicData === void 0 ? void 0 : downloadCenterDynamicData.TotalCount, "+")
                                    : downloadCenterDynamicData === null || downloadCenterDynamicData === void 0 ? void 0 : downloadCenterDynamicData.TotalCount),
                                "\u00A0",
                                React.createElement(LocalizedLabel, { section: "Search", label: "Results" })), (_k = downloadCenterDynamicData === null || downloadCenterDynamicData === void 0 ? void 0 : downloadCenterDynamicData.Hits) === null || _k === void 0 ? void 0 :
                            _k.slice(0, itemsNumber).map(function (item, idx) { return React.createElement(DynamicDownloadItem, __assign({}, item, { key: idx })); }),
                            React.createElement(React.Fragment, null, downloadCenterDynamicData &&
                                downloadCenterDynamicData.TotalCount > itemsNumber && (React.createElement(Col, { className: styles.moreButton },
                                React.createElement(Button, { type: "secondary", onClick: function () { return setItemsNumber(itemsNumber + 30); } },
                                    React.createElement(LocalizedLabel, { section: "Search", label: "LoadMore" })))))))))),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            isUserRetention && contactExpertData && (React.createElement(ContactExpert, __assign({}, contactExpertData)))),
        React.createElement(Footer, __assign({}, startPageData))));
}
var useFiltersStyles = createUseStyles({
    mainElement: __assign({ marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.m),
    })),
    teaserContentItem: {
        "& > div": __assign({ marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
            marginBottom: rem(spacings.m),
        })),
    },
    categoryItem: {
        position: "relative",
        marginBottom: rem(spacings.s),
    },
    subCategories: function (toggleSubCategories) { return ({
        paddingLeft: rem(spacings.m),
        maxHeight: toggleSubCategories ? "2000px" : 0,
        opacity: toggleSubCategories ? 1 : 0,
        pointerEvents: toggleSubCategories ? "all" : "none",
        overflow: toggleSubCategories ? "visible" : "hidden",
        transition: "opacity 0.3s ease, max-height 0.3s ease, padding 0.3s ease",
    }); },
    toggler: {
        padding: 0,
        border: "none",
        font: "inherit",
        color: "inherit",
        textAlign: "inherit",
        background: "none",
        position: "absolute",
        top: 0,
        right: 0,
    },
    icon: FAIconStyles({
        width: 24,
        height: 24,
    }),
});
function FiltersElement(props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var localizationCtx = useContext(LocalizationContext);
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var tabletAtLeast = isDeviceResOrHigher(deviceType, "tablet");
    var styles = useFiltersStyles();
    return (React.createElement(React.Fragment, null, (((_a = props.categories) === null || _a === void 0 ? void 0 : _a.length) > 0 ||
        props.markets ||
        props.languages ||
        props.companies ||
        props.typesOfFiles) && (React.createElement(Accordion, { noOuterBorder: isMobile, className: styles.mainElement },
        ((_b = props.categories) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement(AccordionItem, { headline: localizedLabelString({
                section: "DownloadCenterPage",
                label: "Categories",
                localizationCtx: localizationCtx,
            }), initialIsActive: tabletAtLeast }, (_c = props.categories) === null || _c === void 0 ? void 0 : _c.map(function (category, idx) { return (React.createElement(Category, { category: category.category, subcategories: category.subcategories, categoryFilters: props.categoryFilters, setCategoryFilters: props.setCategoryFilters, key: idx })); }))),
        props.markets && (React.createElement(AccordionItem, { headline: localizedLabelString({
                section: "DownloadCenterPage",
                label: "Market",
                localizationCtx: localizationCtx,
            }), initialIsActive: tabletAtLeast && props.marketFilters.size > 0 }, (_d = props.markets) === null || _d === void 0 ? void 0 : _d.map(function (option) { return (React.createElement(Checkbox, { onCheck: function () {
                return props.setMarketFilters(function (s) {
                    return switchSetOption(s, option.contentLink.id.toString() || "");
                });
            }, checked: props.marketFilters.has(option.contentLink.id.toString() || ""), label: option.name, key: option.contentLink.id })); }))),
        props.languages && (React.createElement(AccordionItem, { headline: localizedLabelString({
                section: "DownloadCenterPage",
                label: "Language",
                localizationCtx: localizationCtx,
            }), initialIsActive: tabletAtLeast && !!props.activeLanguage },
            React.createElement(RadioButton, { onCheck: function () { return props.setActiveLanguage("showAll"); }, checked: props.activeLanguage === "showAll", label: props.allLanguagesLabel }), (_e = props.languages) === null || _e === void 0 ? void 0 :
            _e.map(function (option) { return (React.createElement(React.Fragment, null,
                React.createElement(RadioButton, { key: option.contentLink.id, onCheck: function () {
                        return props.setActiveLanguage(option.contentLink.id.toString());
                    }, checked: props.activeLanguage === option.contentLink.id.toString(), label: namePropertyValue(option.name) || "" }))); }))),
        props.companies && (React.createElement(AccordionItem, { headline: localizedLabelString({
                section: "DownloadCenterPage",
                label: "Company",
                localizationCtx: localizationCtx,
            }), initialIsActive: tabletAtLeast && !!props.activeCompany },
            React.createElement(RadioButton, { onCheck: function () { return props.setActiveCompany(null); }, checked: !props.activeCompany, label: props.allCompaniesLabel }), (_f = props.companies) === null || _f === void 0 ? void 0 :
            _f.map(function (option) { return (React.createElement(RadioButton, { key: option.contentLink.id, onCheck: function () {
                    return props.setActiveCompany(option.contentLink.id.toString());
                }, checked: props.activeCompany === option.contentLink.id.toString(), label: namePropertyValue(option.name) || "" })); }))),
        props.typesOfFiles && (React.createElement(AccordionItem, { headline: localizedLabelString({
                section: "DownloadCenterPage",
                label: "TypeOfFiles",
                localizationCtx: localizationCtx,
            }), initialIsActive: tabletAtLeast && props.typeOfFileFilters.size > 0 }, (_g = props.typesOfFiles) === null || _g === void 0 ? void 0 : _g.map(function (option) { return (React.createElement(Checkbox, { onCheck: function () {
                return props.setTypeOfFileFilters(function (s) {
                    return switchSetOption(s, option.contentLink.id.toString() || "");
                });
            }, checked: props.typeOfFileFilters.has(option.contentLink.id.toString() || ""), label: option.name, key: option.contentLink.id })); })))))));
}
function Category(props) {
    var _a, _b, _c, _d, _e;
    var _f = useState(false), toggleSubCategories = _f[0], setToggleSubCategories = _f[1];
    var activeCategoriesArray = Array.from(props.categoryFilters);
    var subcategoriesArray = props.subcategories.map(function (subcategory) { var _a; return (_a = subcategory.contentLink) === null || _a === void 0 ? void 0 : _a.id.toString(); });
    var notEverySubcategoriesChecked = !subcategoriesArray.every(function (subcategory) {
        return activeCategoriesArray.includes(subcategory);
    });
    var someSubcategoriesChecked = subcategoriesArray.some(function (subcategory) {
        return activeCategoriesArray.includes(subcategory);
    });
    var shouldBeBlackbox = notEverySubcategoriesChecked && someSubcategoriesChecked;
    var toggler = useRef(null);
    var styles = useFiltersStyles(toggleSubCategories);
    return (React.createElement("div", { className: styles.categoryItem, key: (_a = props.category) === null || _a === void 0 ? void 0 : _a.contentLink.id },
        React.createElement(Checkbox, { onCheck: function () {
                return props.setCategoryFilters(function (s) {
                    var _a;
                    return switchSetOption(s, ((_a = props.category) === null || _a === void 0 ? void 0 : _a.contentLink.id.toString()) || "", props.subcategories);
                });
            }, checked: props.categoryFilters.has(((_b = props.category) === null || _b === void 0 ? void 0 : _b.contentLink.id.toString()) || ""), label: (_c = props.category) === null || _c === void 0 ? void 0 : _c.name, blackbox: props.subcategories.length > 0 && shouldBeBlackbox }),
        ((_d = props.subcategories) === null || _d === void 0 ? void 0 : _d.length) > 0 && (React.createElement("button", { onClick: function () { return setToggleSubCategories(!toggleSubCategories); }, className: styles.toggler, ref: toggler, onMouseUp: function () { return toggler.current && toggler.current.blur(); } }, toggleSubCategories ? (React.createElement(React.Fragment, null,
            React.createElement(FAIcon, { icon: "chevron-up", className: styles.icon }))) : (React.createElement(React.Fragment, null,
            React.createElement(FAIcon, { icon: "chevron-down", className: styles.icon }))))),
        toggleSubCategories && (React.createElement("div", { className: styles.subCategories, "aria-expanded": toggleSubCategories }, (_e = props.subcategories) === null || _e === void 0 ? void 0 : _e.map(function (sc) { return (React.createElement(Checkbox, { key: sc.contentLink.id, onCheck: function () {
                return props.setCategoryFilters(function (s) {
                    return switchSetOption(s, sc.contentLink.id.toString() || "");
                });
            }, checked: props.categoryFilters.has(sc.contentLink.id.toString() || ""), label: sc.name })); })))));
}
