var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, onBefore, onBreakpoint, rem, Row, } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { customTypography, typography } from "@basics/typography";
import { spacings } from "@basics/spacings";
import { Button, ButtonWrapper } from "@components/Button/Button";
import { RenderProperty } from "@/src/views/RenderProperty";
import { LocalizedLabel, localizedLabelString, LocalizationContext, } from "@hooks/LocalizationContext";
import cn from "classnames";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { DownloadItem } from "@components/DownloadItem/DownloadItem";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { brandColors } from "@basics/brand-colors";
export var downloadStyles = createUseStyles({
    downloadsBackground: function (_a) {
        var isFullWidth = _a.isFullWidth;
        return ({
            backgroundColor: isFullWidth
                ? brandColors.white
                : brandColors.ultraLightGrey,
        });
    },
    downloads: __assign(__assign({ marginBottom: rem(spacings.m), paddingTop: rem(spacings.m), paddingBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
        paddingTop: rem(spacings.l),
        paddingBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
        paddingTop: rem(spacings.xxl),
        paddingBottom: rem(spacings.xxl),
    })),
    infoSection: __assign({}, onBefore("md", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        marginBottom: rem(spacings.xl),
    })),
    headline: __assign(__assign({}, typography.h2), { wordBreak: "break-all" }),
    description: __assign({}, customTypography(__assign({}, typography.textDefault), {}, {
        marginBottom: rem(spacings.m),
    })),
    moreButton: {
        display: "flex",
        justifyContent: "center",
        paddingTop: rem(spacings.m),
    },
});
export function DownloadBlockWrapper(props) {
    var _a;
    return (React.createElement(DownloadBlock, { downloadItems: props.data.downloads.expandedValue, headline: props.data.headline, headerSize: props.data.headerSize, description: props.data.description, buttons: props.data.buttons, disableFeaturedOption: (_a = props.context) === null || _a === void 0 ? void 0 : _a.disableFeaturedOption }));
}
export function DownloadBlock(props) {
    var _a, _b, _c, _d;
    var _e = useContext(GlobalContentStoreContext), getStartPageData = _e.getStartPageData, getImmediateStartPageData = _e.getImmediateStartPageData;
    var _f = useState(getImmediateStartPageData()), startPageData = _f[0], setStartPageData = _f[1];
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    function getFeaturedSortedItems() {
        var _a, _b, _c, _d;
        var featuredLocal = ((_a = props.downloadItems) === null || _a === void 0 ? void 0 : _a.filter(function (di) { return di.contentType[1] === "DocumentFile"; }).filter(function (mf) { var _a; return (_a = mf === null || mf === void 0 ? void 0 : mf.isFeatured) === null || _a === void 0 ? void 0 : _a.value; })) || [];
        var standardLocal = ((_b = props.downloadItems) === null || _b === void 0 ? void 0 : _b.filter(function (di) { return di.contentType[1] === "DocumentFile"; }).filter(function (mf) { var _a; return !((_a = mf === null || mf === void 0 ? void 0 : mf.isFeatured) === null || _a === void 0 ? void 0 : _a.value); })) || [];
        var featuredGlobal = ((_c = props.downloadItems) === null || _c === void 0 ? void 0 : _c.filter(function (di) { return di.contentType[1] === "ProductDownloadBlock"; }).filter(function (mf) { var _a, _b, _c; return (_c = (_b = (_a = mf.masterFile) === null || _a === void 0 ? void 0 : _a.expandedValue) === null || _b === void 0 ? void 0 : _b.isFeatured) === null || _c === void 0 ? void 0 : _c.value; })) || [];
        var standardGlobal = ((_d = props.downloadItems) === null || _d === void 0 ? void 0 : _d.filter(function (di) { return di.contentType[1] === "ProductDownloadBlock"; }).filter(function (mf) { var _a, _b, _c; return !((_c = (_b = (_a = mf.masterFile) === null || _a === void 0 ? void 0 : _a.expandedValue) === null || _b === void 0 ? void 0 : _b.isFeatured) === null || _c === void 0 ? void 0 : _c.value); })) || [];
        return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], featuredLocal, true), featuredGlobal, true), standardLocal, true), standardGlobal, true);
    }
    var sortedItems = props.disableFeaturedOption
        ? props.downloadItems
        : getFeaturedSortedItems();
    var styles = downloadStyles({ isFullWidth: props.isFullWidth });
    var localizationCtx = useContext(LocalizationContext);
    if (!startPageData)
        return null;
    var downloadCenterUrl = (_b = (_a = startPageData === null || startPageData === void 0 ? void 0 : startPageData.downloadCenter) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.url;
    return (React.createElement(Container, { className: cn(styles.downloadsBackground, !props.isFullWidth && styles.downloads), isFluid: true },
        React.createElement(Container, null,
            React.createElement(Row, null,
                !props.isFullWidth && (React.createElement(Col, { md: 4 },
                    React.createElement(Row, null,
                        React.createElement(Col, { sm: 8, smStart: 2, md: 12, mdStart: null, className: styles.infoSection },
                            React.createElement(SemanticHeader, { className: styles.headline, headerSize: (_c = props.headerSize) === null || _c === void 0 ? void 0 : _c.value },
                                React.createElement(RenderProperty, { value: props.headline, fallbackValue: localizedLabelString({
                                        section: "Download",
                                        label: "Headline",
                                        localizationCtx: localizationCtx,
                                    }) })),
                            React.createElement("span", { className: styles.description },
                                React.createElement(RenderProperty, { value: props.description, fallbackValue: localizedLabelString({
                                        section: "Download",
                                        label: "Description",
                                        localizationCtx: localizationCtx,
                                    }) })),
                            props.buttons ? (React.createElement(React.Fragment, null, (_d = props.buttons.value) === null || _d === void 0 ? void 0 : _d.map(function (b, idx) { return (React.createElement(ButtonWrapper, __assign({}, b, { key: idx }))); }))) : (downloadCenterUrl && (React.createElement(Button, { type: "secondary", href: downloadCenterUrl },
                                React.createElement(LocalizedLabel, { section: "Download", label: "CenterButton" })))))))),
                React.createElement(Col, { md: props.isFullWidth ? 12 : 8 }, sortedItems === null || sortedItems === void 0 ? void 0 : sortedItems.map(function (di, idx) {
                    var contentType = di.contentType.join("/");
                    return contentType === "Block/ProductDownloadBlock" ? (React.createElement(DownloadItem, __assign({}, di.masterFile.expandedValue, { localizedFiles: di.localizedFiles
                            .expandedValue, context: {
                            disableFeaturedOption: props.disableFeaturedOption,
                        }, key: idx }))) : (React.createElement(DownloadItem, __assign({}, di, { context: {
                            disableFeaturedOption: props.disableFeaturedOption,
                        }, key: idx })));
                }))))));
}
