var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useState, useEffect, useRef } from "react";
import { Header } from "@components/Header/Header";
import { Footer } from "@components/Shared/Footer";
import { RenderProperty } from "@/src/views/RenderProperty";
import { KeyVisual } from "@components/KeyVisual/KeyVisual";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { RelatedContentBlock } from "@components/Block/RelatedContentBlock";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import { DynamicDownloadBlock } from "@components/Block/DynamicDownloadBlock";
export function ProductPage(props) {
    var _a, _b, _c;
    var _d = useContext(GlobalContentStoreContext), getStartPageData = _d.getStartPageData, getImmediateStartPageData = _d.getImmediateStartPageData;
    var _e = useState(getImmediateStartPageData()), startPageData = _e[0], setStartPageData = _e[1];
    var contactListRef = useRef(null);
    var keyVisualRef = useRef(null);
    var headerButtonPortalRef = useRef(null);
    var isUserRetention = ((_a = props.data.contactCta) === null || _a === void 0 ? void 0 : _a.value) === "enabled";
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    if (!startPageData)
        return null;
    var contactExpertData = ((_b = props.data.contactsUseDefaultContent) === null || _b === void 0 ? void 0 : _b.value)
        ? startPageData
        : props.data;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, __assign({}, startPageData, { contactListRef: contactListRef, keyVisualRef: keyVisualRef, headerButtonPortalRef: headerButtonPortalRef })),
        React.createElement("main", { "data-testid": "ProductPage" },
            React.createElement(KeyVisual, __assign({}, props.data.keyVisual, { contactListRef: contactListRef, keyVisualRef: keyVisualRef, headerButtonPortalRef: headerButtonPortalRef, isUserRetention: isUserRetention })),
            React.createElement(RenderProperty, { value: props.data.keyFacts }),
            React.createElement(RenderProperty, { value: props.data.createInterest }),
            React.createElement(RenderProperty, { value: props.data.segmentUsers }),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            React.createElement(RenderProperty, { value: props.data.createTrust }),
            React.createElement(DynamicDownloadBlock, { downloadCategoriesFilter: props.data.downloadCategoriesFilter, downloadsManual: props.data.downloadsManual }),
            isUserRetention && (React.createElement("div", { ref: contactListRef },
                React.createElement(ContactExpert, __assign({}, contactExpertData)))),
            ((_c = props.data.relatedContent) === null || _c === void 0 ? void 0 : _c.value) && (React.createElement(RelatedContentBlock, { relatedContent: props.data.relatedContent }))),
        React.createElement(Footer, __assign({}, startPageData))));
}
