var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useReducer } from "react";
import { endpointEnabled } from "@/src/utils/config";
export function getUniqueId(item) {
    var contentLink = item.hasOwnProperty("contentLink")
        ? item.contentLink
        : item;
    return "".concat(contentLink.id);
}
// INFO(mkarol): Annotate props with key
function fieldNameAnnotator(content) {
    return Object.entries(content).reduce(function (acc, _a) {
        var _b;
        var key = _a[0], value = _a[1];
        return (__assign(__assign({}, acc), (_b = {}, _b[key] = typeof value === "object" && value !== null
            ? Array.isArray(value)
                ? value.map(function (item, idx) {
                    return typeof item === "object" && item !== null
                        ? fieldNameAnnotator(__assign(__assign({}, item), { __key__: "".concat(key, "[").concat(idx, "]") }))
                        : item;
                })
                : fieldNameAnnotator(__assign(__assign({}, value), { __key__: key }))
            : value, _b)));
    }, {});
}
function extractContentMapping(content, visitedIds) {
    var id = getUniqueId(content);
    if (visitedIds.has(id))
        return [];
    var updatedVisitedIds = new Set(visitedIds).add(id);
    var children = Object.values(content)
        .flatMap(function (value) {
        return value && value.expandedValue
            ? Array.isArray(value.expandedValue)
                ? value.expandedValue.flatMap(function (ev) {
                    return extractContentMapping(ev, updatedVisitedIds);
                })
                : extractContentMapping(value.expandedValue, updatedVisitedIds)
            : undefined;
    })
        .filter(Boolean);
    var mapping = [id, content];
    return __spreadArray([mapping], children, true);
}
function reducer(state, action) {
    switch (action.type) {
        case "initializeStore": {
            return __assign({}, action.newState);
        }
        case "update": {
            return __assign(__assign({}, state), { mapping: new Map(__spreadArray(__spreadArray([], Array.from(state.mapping.entries()), true), action.newMapping, true)) });
        }
        default:
            throw new Error();
    }
}
export function initGlobalContentStore(initialData, isEditing) {
    var _a, _b;
    return {
        mapping: isEditing
            ? new Map(__spreadArray(__spreadArray(__spreadArray([], ((_a = initialData.globalSettings.navigation.menuContentArea) !== null && _a !== void 0 ? _a : []).flatMap(function (item) {
                return extractContentMapping(fieldNameAnnotator(item), new Set());
            }), true), extractContentMapping(fieldNameAnnotator(initialData.startPageData), new Set()), true), extractContentMapping(fieldNameAnnotator(initialData.content), new Set()), true))
            : new Map(__spreadArray(__spreadArray(__spreadArray([], ((_b = initialData.globalSettings.navigation.menuContentArea) !== null && _b !== void 0 ? _b : []).flatMap(function (item) { return extractContentMapping(item, new Set()); }), true), extractContentMapping(initialData.startPageData, new Set()), true), extractContentMapping(initialData.content, new Set()), true)),
        startPageContentLink: initialData.startPageData.contentLink,
        navigation: initialData.globalSettings.navigation,
        breadcrumb: initialData.globalSettings.breadcrumb,
        contacts: initialData.globalSettings.contacts,
        website: initialData.website,
        mainPageContentLink: initialData.contentLink,
        isInitialized: true,
    };
}
var getInitialData = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, fetch("/api/hdiglobal/content?path=".concat(window.location.pathname))];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3:
                error_1 = _a.sent();
                window.location.replace("/error");
                throw error_1;
            case 4: return [2 /*return*/];
        }
    });
}); };
export function useGlobalContentStore(initialData, isEditing) {
    var _this = this;
    useEffect(function () {
        var fetchData = function () { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getInitialData()];
                    case 1:
                        data = _a.sent();
                        dispatch({
                            type: "initializeStore",
                            newState: initGlobalContentStore(data, isEditing),
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        endpointEnabled ? fetchData() : null;
    }, [isEditing]);
    var _a = useReducer(reducer, (initialData && initGlobalContentStore(initialData, isEditing)) || {
        mapping: new Map(),
        startPageContentLink: null,
        breadcrumb: null,
        navigation: null,
        contacts: null,
        website: null,
        mainPageContentLink: null,
        isInitialized: false,
    }), state = _a[0], dispatch = _a[1];
    function getByContentLinkId(contentLinkId) {
        return state.mapping.get(contentLinkId);
    }
    function getFromAPIByLinkId(contentLinkId) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var searchQueryParams;
            return __generator(this, function (_b) {
                searchQueryParams = new URLSearchParams();
                searchQueryParams.append("expand", "*");
                searchQueryParams.append("epieditmode", "True");
                searchQueryParams.append("cacheBuster", Math.round(Math.random() * 100000000).toString());
                searchQueryParams.append("location", encodeURI(window.location.pathname));
                return [2 /*return*/, fetch("/api/episerver/v3.0/content/".concat(contentLinkId, "?").concat(searchQueryParams.toString()), {
                        headers: [
                            ["Accepted-Language", ((_a = state.website) === null || _a === void 0 ? void 0 : _a.currentLanguage.name) || "en"],
                        ],
                    }).then(function (r) { return r.json(); })];
            });
        });
    }
    function getByContentLink(contentLink) {
        return __awaiter(this, void 0, void 0, function () {
            var id;
            return __generator(this, function (_a) {
                id = getUniqueId(contentLink);
                return [2 /*return*/, getByContentLinkId(id) || getFromAPIByLinkId(id)];
            });
        });
    }
    function isInitialized() {
        return state.isInitialized;
    }
    function updateWithContentLinkId(contentLinkId) {
        return __awaiter(this, void 0, void 0, function () {
            var newContent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getFromAPIByLinkId(contentLinkId)];
                    case 1:
                        newContent = _a.sent();
                        ({
                            type: "update",
                            newMapping: extractContentMapping(fieldNameAnnotator(newContent), new Set()),
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function getStartPageData() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, (state.startPageContentLink &&
                        getByContentLink(state.startPageContentLink))];
            });
        });
    }
    function getImmediateByContentLink(contentLink) {
        var id = getUniqueId(contentLink);
        return getByContentLinkId(id) || null;
    }
    function getImmediateStartPageData() {
        return ((state.startPageContentLink &&
            getImmediateByContentLink(state.startPageContentLink)) ||
            null);
    }
    function getNavigation() {
        return state.navigation;
    }
    function getBreadcrumbs() {
        return state.breadcrumb;
    }
    function getContacts() {
        return state.contacts;
    }
    function getWebsiteData() {
        return state.website;
    }
    function getMainPageContentLink() {
        return state.mainPageContentLink;
    }
    return {
        getByContentLink: getByContentLink,
        updateWithContentLinkId: updateWithContentLinkId,
        getStartPageData: getStartPageData,
        getNavigation: getNavigation,
        getBreadcrumbs: getBreadcrumbs,
        getContacts: getContacts,
        getWebsiteData: getWebsiteData,
        isInitialized: isInitialized,
        getImmediateByContentLink: getImmediateByContentLink,
        getImmediateStartPageData: getImmediateStartPageData,
        getMainPageContentLink: getMainPageContentLink,
        getByContentLinkId: getByContentLinkId,
    };
}
export var GlobalContentStoreContext = React.createContext({
    getByContentLink: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, null];
    }); }); },
    getStartPageData: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, null];
    }); }); },
    getNavigation: function () { return null; },
    getBreadcrumbs: function () { return null; },
    getContacts: function () { return null; },
    getWebsiteData: function () { return null; },
    getMainPageContentLink: function () { return null; },
    isInitialized: function () { return false; },
    getImmediateByContentLink: function () { return null; },
    getImmediateStartPageData: function () { return null; },
    getByContentLinkId: function () { return undefined; },
});
