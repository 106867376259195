import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { FAIcon } from "@components/FAIcon/FAIcon";
import { shadeColor } from "./ColorShader";
import { markerBackgroundColors, markerColors, } from "./InteractiveMapBlock";
var useStyles = createUseStyles({
    "@keyframes slideRight": {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
    marker: function (_a) {
        var size = _a.size, isAnimated = _a.isAnimated, animationPeriod = _a.animationPeriod;
        return {
            animation: isAnimated
                ? ""
                : "$slideRight ".concat(animationPeriod, "s ease-in-out 1"),
            "&:hover": {
                transform: "scale(1.2) translateX(".concat((-size * 0.2) / 2, "px) translateY(").concat((-size * 0.2) / 2, "px)"),
                transition: "transform 0.3s ease",
            },
            transform: "scale(1)",
            transition: "transform 0.3s ease",
        };
    },
});
var getBackgroundColorFor = function (iconColor) {
    return markerBackgroundColors[iconColor];
};
export var LocationIcon = function (_a) {
    var size = _a.size, color = _a.color, isHovered = _a.isHovered, icon = _a.icon, hasAnimation = _a.hasAnimation;
    var animationPeriod = 0.8;
    var _b = useState(false), isAnimated = _b[0], setIsAnimated = _b[1];
    var marker = useStyles({ size: size, isAnimated: isAnimated, animationPeriod: animationPeriod }).marker;
    var backgroundColor = getBackgroundColorFor(color);
    var markerClasses = hasAnimation ? marker : "";
    return (React.createElement("g", { transform: "translate(".concat(-size / 2, ", ").concat(-size / 2, ")") },
        React.createElement("g", { className: markerClasses, onAnimationEnd: function () { return setIsAnimated(true); } },
            React.createElement("circle", { cx: size / 2, cy: size / 2, r: size / 1.25, fill: isHovered ? shadeColor(backgroundColor, -4) : backgroundColor }),
            React.createElement(FAIcon, { icon: icon, width: size, height: size, color: markerColors[color] }))));
};
